import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ApplicantAuthService from '../Services/ApplicantAuthService';
import UpdateAndRoutingService from '../Services/UpdateAndRoutingService';
import PageEnum from '../Util/PageEnum';
import ErrorBanner from './ErrorBanner';
import queryString from 'query-string';
import DeAuthenticationService from "../Services/DeAuthenticationService";
import RequisitionService from "../Services/RequisitionService";
import StoreDetailsService from "../Services/StoreDetailsService";
import CandidateStatusService from "../Services/CandidateStatusService";
import PhoneScreenService from "../Services/PhoneScreenService";


function AuthenticationForm(props) {
    return (
        <form onSubmit={e => { props.submitHandler(e)}}>
            <Row bsClass="row paddedForm">
                <Col sm={12} >
                    <div className="form-group">
                        <label htmlFor="candID">Candidate ID</label>

                        <input type="text" className={props.message.candID ? "form-control validationError" : "form-control"} id="candID" placeholder="Enter Number" defaultValue={props.canID.val}  onChange={e => { props.candIDOnChangeHandler(e) }} />
                        {props.message.candID ? <div className="formErrorMessage" >{props.message.candID}</div> : ''}
                    </div>
                </Col>
                <Col sm={12} >
                    <div className="form-group">
                        <label htmlFor="tbLastName">Last Name</label>
                        <input type="text" className={props.message.lastName ? "form-control validationError" : "form-control"} id="tbLastName" placeholder="Enter Name" onChange={e => { props.lastNameOnChangeHandler(e) }} />
                        {props.message.lastName ? <div className="formErrorMessage" >{props.message.lastName}</div> : ''}
                    </div>
                </Col>
                <Col sm={12} >
                    <div className="form-group">
                        <label htmlFor="tbZipCode">Zip Code</label>
                        <input type="text" className={props.message.zipcode ? "form-control validationError" : "form-control"} id="tbZipCode" placeholder="Enter 5 Digits" onChange={e => { props.zipcodeOnChangeHandler(e) }} />
                        {props.message.zipcode ? <div className="formErrorMessage" >{props.message.zipcode}</div> : ''}
                    </div>
                </Col>
                <Col sm={12} className='buttonSpacing'>
                    <button
                        className='button primary submitbutton'
                        type='submit'
                    >
                        Let's Begin
                    </button>
                </Col>
            </Row>
        </form>
    )
}

AuthenticationForm.propTypes = {
    submitHandler: PropTypes.func.isRequired, 
    candIDOnChangeHandler: PropTypes.func.isRequired, 
    lastNameOnChangeHandler: PropTypes.func.isRequired, 
    zipcodeOnChangeHandler: PropTypes.func.isRequired,
    message:PropTypes.object
};



class BasicAuthentication extends React.Component {
    constructor(props) {
        super(props);
        var urlCandID = '';
        var urlSMS = 'N';
        if(queryString.parse(props.location.search).candID){
            urlCandID = queryString.parse(props.location.search).candID;
        }

        this.state = {
            lastName: { length: 0, errorMessage: '', val: ''},
            candID: { length: urlCandID.length, errorMessage: '', val: urlCandID, submitOnlyErrorMessage: ''},
            zipcode: { length: 0, errorMessage: '', val: '', submitOnlyErrorMessage: ''},
            loading: false,
            submitErrorMessage: '',
            sms: 'N'
        }
    }

    UNSAFE_componentWillMount() {
        var qs = queryString.parse(this.props.location.search);
        if (qs.candID){
            this.setState(function(){
                return {
                    loading: true
                }
            });
            ApplicantAuthService.getApplicantInfoIfActive(qs.candID).then(this.getApplicantInfoIfActiveOnComplete);
        }
        if (qs.sms){
            this.setState(function(){
                return {
                    sms: qs.sms
                }
            });
        }

        if(qs.error != null && qs.error === "session")
        {
            this.setState(function(){
                return {
                    submitErrorMessage: 'Your session has expired or is invalid.  Please re-enter your credentials to continue.'
                }});
        }
        this.deAuthentication();
    }

    deAuthentication = () => {
        window.sessionStorage.removeItem('CSSAuthenticated');
        window.sessionStorage.removeItem('applicantID');
        DeAuthenticationService.logout().then();
    }

    getApplicantInfoIfActiveOnComplete = (response) => {
        //Store the applicantID globally for all pages to use

        window.sessionStorage.setItem('applicantID', response.data.applicantId);
        if(response && response.data && response.data.candidateID){
            this.state.candID.val = response.data.candidateID;
            this.state.candID.length = 10;
            this.setState(function(){
                return {
                    loading: false
                }
            });
        } else {
            this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=" + response.data.messageId});
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState(function() {
            return {
                submitErrorMessage: ""
            }
        });
        var tmpState = this.setErrorCodeMessages();
        var submit = true;

        if(!(Object.keys(tmpState).length === 0 && tmpState.constructor === Object)) {
            this.setState(function() {
                return tmpState;
            }, function() {
                if(this.state.candID.errorMessage || this.state.zipcode.errorMessage || this.state.lastName.errorMessage){
                    submit = false;
                }
                if(submit) {
                    this.submitForm();
                }
            });
        } else {
            if(this.state.candID.errorMessage || this.state.zipcode.errorMessage || this.state.lastName.errorMessage){
                submit = false;
            }
            if(submit) {
                this.submitForm();
            }
        }
    }

    lastNameOnChangeHandler = (event) => {
        var oValue = event.target.value;
        var oErrorMessage = this.state.lastName.errorMessage;
        var oLength = oValue.length;

        return this.setState(function() {
            return {
                lastName: { length: oLength, errorMessage: oErrorMessage, val:oValue }
            }
        })
    }

    closeEventHandler = () => {
        this.setState(function() {
            return {
                submitErrorMessage:''
            }
        })
    }

    candIDOnChangeHandler = (event) => {
        var oValue = event.target.value;
        var isNum = /^\d+$/.test(oValue);
        var oErrorMessage = '';
        var oSubmitOnlyErrorMessage = '';
        var oLength = oValue.length;

        if(!isNum && oValue){
            oErrorMessage =  "Please enter numeric characters only.";
        }

        if( isNum && oLength > 10){
            oErrorMessage = "Candidate ID Must be 10 digits.";
        } else if(isNum && oLength < 10) {
            oSubmitOnlyErrorMessage = "Candidate ID Must be 10 digits.";
        }

        this.setState(function() {
            return {
                candID: { length: oLength, errorMessage: oErrorMessage, val:oValue, submitOnlyErrorMessage: oSubmitOnlyErrorMessage }
            }
        })
    }

    zipcodeOnChangeHandler = (event) => {
        let oValue = event.target.value;
        var isNum = /^\d+$/.test(oValue);
        var oErrorMessage = '';
        var oSubmitOnlyErrorMessage = '';
        var oLength = oValue.length;

        if(!isNum && oValue){
            oErrorMessage =  "Please enter numeric characters only.";
        }
        
        if( isNum && oLength > 5){
            oErrorMessage = "Zipcode must be 5 digits.";
        } else if(isNum && oLength < 5) {
            oSubmitOnlyErrorMessage = "Zipcode must be 5 digits.";
        }

        this.setState(function() {
            return {
                zipcode: { length: oLength, errorMessage: oErrorMessage, val:oValue, submitOnlyErrorMessage: oSubmitOnlyErrorMessage }
            }
        })
    }

    setErrorCodeMessages = () => {
        var tmpState = {};

        var zipcodeMessage = this.setZipcodeErrorMessages();
        var candIDMessages = this.setCandIDErrorMessages();
        var lastNameMessages = this.setLastNameErrorMessages();

        if(zipcodeMessage){
            tmpState.zipcode = zipcodeMessage.zipcode;
        }

        if(candIDMessages){
            tmpState.candID = candIDMessages.candID;
        }

        tmpState.lastName = lastNameMessages.lastName;
        
        return tmpState;
    }

    setZipcodeErrorMessages = () => {
        var tmpState = {};
        
        if(this.state.zipcode.submitOnlyErrorMessage){
            tmpState.zipcode = { length: this.state.zipcode.length, errorMessage: this.state.zipcode.submitOnlyErrorMessage, val:this.state.zipcode.val, submitOnlyErrorMessage: '' }
        } else if(this.state.zipcode.length === 0){
            tmpState.zipcode = { length: 0, errorMessage: "Please enter your zipcode.", val:"", submitOnlyErrorMessage: '' }
        }

        if(tmpState.hasOwnProperty("zipcode")){
            return tmpState;
        } else {
            return null;
        }
    }

    setCandIDErrorMessages = () => {
        var tmpState = {};

        
        if(this.state.candID.submitOnlyErrorMessage) {
            tmpState.candID = { length: this.state.candID.length, errorMessage: this.state.candID.submitOnlyErrorMessage, val:this.state.candID.val, submitOnlyErrorMessage: '' }  
        } else if(this.state.candID.length === 0){
            tmpState.candID = { length: 0, errorMessage: "Must be 10 digits.", val:"", submitOnlyErrorMessage: '' }
        }

        if(tmpState.hasOwnProperty("candID")){
            return tmpState;
        } else {
            return null;
        }
    }

    setLastNameErrorMessages = () => {
        var tmpState = {};

        if(this.state.lastName.length === 0) {
            tmpState.lastName = { length: 0, errorMessage: "Please enter your last name.", val:"", submitOnlyErrorMessage: '' }
        } else {
            tmpState.lastName = { length: this.state.lastName.length, errorMessage: "", val:this.state.lastName.val, submitOnlyErrorMessage: '' }
        }

        return tmpState;
    }

    submitForm =  () => {
         ApplicantAuthService.authenticateWithLastnameCandIdAndZipcode(this.state.candID.val, this.state.lastName.val, this.state.zipcode.val).then(this.authenticateWithLastnameCandIdAndZipcodeOnComplete).catch((error) => {
             let msg;
             console.log(error);
             if (error.response.status === 429) {
                 msg = "Too many attempts have occurred, please verify your information and try again in 10 minutes.";
             } else {
                 msg = "There has been an issue locating your information. Please try again.";
             }
             this.setState(function () {
                 return {
                     submitErrorMessage: msg
                 }
             });
        });
    }

    authenticateWithLastnameCandIdAndZipcodeOnComplete = (response) => {
        this.setState(function() {
            return {
                submitErrorMessage: ""
            }
        });

        if(response.data.authorization && !response.data.messageId){
            var applicantId = response.data.applicantId;

            //Store the applicantID globally for all pages to use

            //Store the applicantID globally for all pages to use
            window.sessionStorage.setItem('applicantID', applicantId);
            window.sessionStorage.setItem('CSSAuthenticated', 'true');

            UpdateAndRoutingService.updateAndGetRouteAuthentication(this.state.sms).then(this.updateAndGetRouteOnComplete);
        } else if(response.data.messageId) {


            window.sessionStorage.setItem('applicantID', response.data.applicantId);
            window.sessionStorage.setItem('CSSAuthenticated', 'true');

            this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=" + response.data.messageId});

            window.sessionStorage.removeItem('applicantID');
            DeAuthenticationService.logout().then();
            window.sessionStorage.removeItem('CSSAuthenticated');

        } else if(!response.data.authorization)
        {
            this.setState(function() {
                return {
                    submitErrorMessage: "<b>Invalid Information:</b> Please confirm your information. Make sure your last name and zip code match what was provided on your application."
                }
            });
        }
    };

    updatePhoneScreenResponse = (response) => {
        if(response.data.rowsUpdated === 1) {
            this.setErrorMessageState("No Error");
        } else {
            this.setErrorMessageState("Error updating Phone Screen Status");
        }
    }

    updateAndGetRouteOnComplete = (response) => {
         if(response.data.msgId){

               if(response.data.msgId !== 28) {
                 this.props.history.push({pathname: PageEnum[(response.data.pages)].url,search: "?msgId=" + response.data.msgId});
               } else {
                   PhoneScreenService.getPhoneScreenDetails(this.state.applicantId).then(res => {
                       if (res.data.statusCode === "4") {
                           this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=" + response.data.msgId});
                       } else {

                           StoreDetailsService.getStoreDeatilsServiceByApplicantId(this.state.applicantId).then(resp => {
                               if ( resp.data.locationTypeCode === 'STR'
                                   && (resp.data.jrFlag === 'Y' || (resp.data.jrFlag === 'P' && resp.data.jrStrFlag === 'Y'))) {
                                   RequisitionService.getAltReqByApplicantId(this.state.applicantId)
                                       .then(altResp => {
                                           let altReqs = altResp.data;
                                           if ( altReqs && altReqs.length > 0) {
                                               CandidateStatusService.updatePhoneScreenStatus(14).then(this.updatePhoneScreenResponse);
                                               this.props.history.push({pathname: PageEnum.AlternateRequisition.url, state : { altReqs: altReqs , authFlag: "Y"}});
                                           } else {
                                               this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=" + 29});
                                           }
                                       })//Alternate req call close
                                       .catch(error => {
                                           console.error("Error:", error);
                                       });
                               }
                               else {
                                   this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=" + 29});
                               }
                           }) // End of Store Details
                           .catch(error => {
                               console.error("Error:", error);});
                       }
                   }).catch(error => {console.error("Error:", error);}); // Phone screen end

           } //End of else msg Id 28
        } //End of msgId check
        else {
            this.props.history.push({pathname: PageEnum[(response.data.pages)].url, search: PageEnum[(response.data.pages)].search});
        }
    }

    

    render() {
        return (
            <div>
             {this.state.loading ? <Row><Col xs={12}>Loading...</Col></Row> : (
                 <div>
                    <ErrorBanner submitErrorMessage={this.state.submitErrorMessage} closeEventHandler={this.closeEventHandler} />
                    <Row>
                        <Col sm={12}>
                            <h2>Welcome</h2>
                        </Col>
                        <Col sm={12}>
                            <p>Please provide your Candidate ID, Last Name and Zip code below. Your Last name and Zip code should match what was provided on your application.  <i>Your Candidate ID can be found on any Home Depot careers email.</i> </p>
                        </Col>
                    </Row>
                    <AuthenticationForm submitHandler={this.submitHandler} candIDOnChangeHandler={this.candIDOnChangeHandler} lastNameOnChangeHandler={this.lastNameOnChangeHandler} canID={{val: this.state.candID.val}}
                    zipcodeOnChangeHandler={this.zipcodeOnChangeHandler} message={{ candID: this.state.candID.errorMessage, zipcode: this.state.zipcode.errorMessage, lastName: this.state.lastName.errorMessage }} />
                </div>
             ) }
            </div>
        )
    }
}

export default BasicAuthentication;