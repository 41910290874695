import React from "react";
import {Col, Row} from "react-bootstrap";
import PageEnum from "../Util/PageEnum";
import DaySchedules from "./DaySchedules";
import RequisitionService from "../Services/RequisitionService";
import UpdateAndRoutingService from "../Services/UpdateAndRoutingService";
import titleCase from "title-case";
import QuestionService from "../Services/QuestionService";
import Loader from "./Loader";
import ErrorBanner from "./ErrorBanner";




class Schedule extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            daySlots: [{dayName: "Monday", sequence:14, newCode:29},
                {dayName: "Tuesday", sequence: 15, newCode: 30},
                {dayName: "Wednesday", sequence: 16, newCode: 31},
                {dayName: "Thursday", sequence: 17, newCode: 32},
                {dayName: "Friday", sequence: 18, newCode: 33},
                {dayName: "Saturday", sequence: 19, newCode: 34},
                {dayName: "Sunday", sequence: 20, newCode: 35}],
            nextDisabled:true,
            matchIndicator: '',
            requisitionId: '',
            errorMessage:'',
            loaded:false,
            moveError: false

        }
    }

    componentDidMount() {
        let applId = window.sessionStorage.getItem('applicantID');
        this.setState(function () {
            return {
                applId: applId
            }
        });

        RequisitionService.getRequisitionDetailsByApplId(window.sessionStorage.getItem('applicantID')).then(this.requisitionDetailsOnComplete);
        RequisitionService.getShiftsByApplicantId(applId).then(this.shiftsOnComplete);
    }

    requisitionDetailsOnComplete = (response) => {
        if (response && response.data) {
            let scheduleMatch = response.data.scheduleIndicator;
            this.setState(function() {
                return {
                    matchIndicator:scheduleMatch
                }
            });
        }
    }

    shiftsOnComplete = (response) => {
        if (response && response.data) {
            let shifts = response.data;
            let currentDays = this.state.daySlots;

            shifts.forEach(shift => {
                currentDays[shift.weekDayCd-1].beginTime = shift.shiftBeginTime;
                currentDays[shift.weekDayCd-1].endTime = shift.shiftEndTime;
                currentDays[shift.weekDayCd-1].overNight = shift.overNightFlag;
            })
            this.setState(function() {
                return {
                    daySlots: currentDays,
                    loaded: true
                }
            });
        }
    }

    clickEventHandler = (index, answer) => {
        let currentDays = this.state.daySlots;
        currentDays[index].answer = answer;
        let nextDisabled = false
        currentDays.forEach(shift => {
            if(shift.beginTime){
                 if(!(("Y"===shift.answer)||("N"===shift.answer))&& !(nextDisabled)){
                     nextDisabled = true;
                 }
            }

        })

        this.setState(function() {
            return {
                daySlots:currentDays,
                nextDisabled:nextDisabled
            }
        });

    }

    nextClickHandler = () => {
        this.setState(function() {
            return {
                loaded:false
            }
        });

        let scheduleRequest = {
            applicantId:this.state.applId,
            questionRequest:[]
        };
        this.state.daySlots.forEach(shift => {
            if((("Y"===shift.answer)||("N"===shift.answer))){
                let questionRequest = {
                    answer:shift.answer,
                    originalCode:shift.sequence,
                    newCode:shift.newCode
                };
                scheduleRequest.questionRequest.push(questionRequest);
            }

            })

        QuestionService.updatePhoneScreenQuestionFlagAndCodesBatch(scheduleRequest).then(this.batchQuestionUpdateOnComplete)
            .catch(this.onServiceError);


    }

    closeEventHandler = (e) => {
        this.setState(function() {
            return {errorMessage: ''}
        });
    }

    onServiceError = (response) => {
            this.setState(function() {
                return {errorMessage: 'An error has occurred.',
                    loaded:true}

            });
    }

    batchQuestionUpdateOnComplete = () => {
        if('P' === this.state.matchIndicator) {
            if ((this.state.daySlots.some(e => 'Y' === e.answer))) {
                UpdateAndRoutingService.updateAndRouteQuestions(this.state.applId).then(this.updateAndRouteQuestionsOnComplete).catch(this.onServiceError);
            }else{
                this.props.history.push({pathname:PageEnum.ScheduleAccommodation.url});
            }
        }else if('M' === this.state.matchIndicator){
            if ((this.state.daySlots.some(e => 'N' === e.answer))) {
                this.props.history.push({pathname:PageEnum.ScheduleAccommodation.url});
            }else{
                UpdateAndRoutingService.updateAndRouteQuestions(this.state.applId).then(this.updateAndRouteQuestionsOnComplete).catch(this.onServiceError);
            }
        }
    }

    updateAndRouteQuestionsOnComplete = (response) => {
        if (response.data.msgId) {
            this.props.history.push({ pathname: PageEnum[titleCase(response.data.pages)].url, search: "?msgId=" + response.data.msgId });
        } else {
            this.props.history.push({ pathname: PageEnum[titleCase(response.data.pages)].url });
        }
    }

    render() {

        return (

            <div>{this.state.loaded ? <div>
                <ErrorBanner submitErrorMessage={this.state.errorMessage} closeEventHandler={this.closeEventHandler} />
                <Row>
                    <Col sm={12} >
                        <div>
                            <h2>Schedule</h2>
                            <p>Below shows the initial work schedule for this position. Schedule is subject to change based on business needs.<br/><br/> Are you able to work the times below?<br/><br/></p>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12}>
                        <DaySchedules days={this.state.daySlots} clickEvent={this.clickEventHandler}></DaySchedules>
                    </Col>
                </Row>
                <Row className='topMargin'>
                    <Col sm={12} md={8} className="largeVerticalMargins">
                        <button  className="button submitbutton bsize Big primary" type="button" onClick={this.nextClickHandler} disabled={this.state.nextDisabled}>
                            Next
                        </button>
                    </Col>

                </Row>
            </div> : <Loader/>}
            </div>
        )
    }
}

export default Schedule;