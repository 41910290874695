import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageEnum from '../Util/PageEnum';
import RequistionService from '../Services/RequisitionService';
import PhoneScreenService from '../Services/PhoneScreenService';
import QuestionService from '../Services/QuestionService';


class MinimumAge extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            applicantId: window.sessionStorage.getItem('applicantID'),
            ageQuestion: { "questionID": '', "descriptionText": '', "questionText": '', "phnScrnQuestionCode": '' },
            reqnId: '',
            errorMessage: '',
            applId: '',
            loaded: true
        }
    }

    disableLoadingIcon = (response) => {
        this.setState(function () {
            return {
                loaded: response
            }
        });
    }

    UNSAFE_componentWillMount() {
        var applId = window.sessionStorage.getItem('applicantID');
        this.disableLoadingIcon(false);
        this.setState(function () {
            return {
                applId: applId
            }
        });

        QuestionService.getMinimumAgeQuestion(applId).then(this.getQuestionsResponse);
    }

    getQuestionsResponse = (response) => {
        if (response && response.data) {
            var questions = response.data;
            this.disableLoadingIcon(true);
            this.setState(function () {
                return {
                    ageQuestion: questions
                }
            });
        }
    }

    yesEventHandler = () => {
        QuestionService.updatePhoneScreenQuestionFlag( this.state.applicantId, this.state.ageQuestion.phnScrnQuestionCode ,"Y").then(this.updatePhoneScreenQuestionFlagOnComplete).catch(() => {
            this.setState(function() {
                return {errorMessage : 'Error processing data.  Please try again.'}
            });
        });
    }

    updatePhoneScreenQuestionFlagOnComplete = (resp) => {
        if(resp && resp.data && resp.data.rowsUpdated === 1){
            //this.props.history.push({ pathname: PageEnum.JobPreview.url});
            this.props.history.push({ pathname: PageEnum.Questions.url });
        } else {
            this.setState(function() {
                return { errorMessage : 'Error processing data.  Please try again.'}
            });
        }
    }

    noEventHandler = () => {
        QuestionService.updatePhoneScreenQuestionFlag( this.state.applicantId, 10,"N").then(this.updatePhoneScreenQuestionOnComplete);
        RequistionService.getRequisitionNumberByApplId(this.state.applicantId).then(this.getRequisitionNumberByApplIdOnComplete);
        PhoneScreenService.updateMinReqAndPhoneScreenStatus(this.state.applicantId, 5, 5).then(this.noEventOnComplete).catch(() => {
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        });

    }

    noEventOnComplete = (resp) => {
        if (resp && resp.data && resp.data.rowsUpdated === 1) {
            if (this.state.ageQuestion.descriptionText.includes("16")) {
                this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=15"});
            } else {
                this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=16"});
            }
        } else {
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    updatePhoneScreenQuestionOnComplete = (resp) => {
        if (!resp || !resp.data || resp.data.rowsUpdated !== 1) {
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    updateInterviewStatusOnComplete = (resp) => {
        if (!resp || !resp.data || resp.data.rowsUpdated !== 1) {
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    getRequisitionNumberByApplIdOnComplete = (resp) => {
        if (!resp || !resp.data) {
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
        else {
            var reqNbr = resp.data.requisitionNumber;
            PhoneScreenService.updateInterviewStatus(this.state.applicantId, reqNbr, 0).then(this.updateInterviewStatusOnComplete);
            this.setState(function () {
                return {
                    reqnId: reqNbr
                }
            });
        }
    }

    render() {
        return (
            <div className="minimumAge">
                <Row>
                    <Col xs={12}>
                        {this.state.ageQuestion.descriptionText}
                    </Col>
                </Row>
                <Row >
                    <Col xs={12}>
                        {this.state.ageQuestion.questionText}
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} sm={4}>
                        <button className="button yesno" type="button" onClick={this.yesEventHandler}> Yes </button>
                        <button className="button yesno" type="button" onClick={this.noEventHandler}> No </button>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default MinimumAge;