import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageEnum from "../Util/PageEnum";
import PositionDetails from "./PositionDetails";
import SchedulingService from "../Services/SchedulingService";
import {createBrowserHistory} from 'history';
import DeAuthenticationService from "../Services/DeAuthenticationService";


class OfferConfirmedDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dayText: '',
            time: '',
            locationLine1: '',
            locationLine2: '',
            position: '',
            timeSlot: '',
            reqnNbr: '',
            scheduleDays: '',
            scheduleTimes:'',
            wage: '',
            employmentType: '',
            bgcRequired: false,
            drugTestRequired: false,
            rescheduled: false,
            availability: true,
            timeLeftToReschedule: true,
            accommodation: false,
            eventAddress1: '',
            eventAddress2: '',
            eventAddress3: ''

        }
    }

    componentDidMount() {
        this.updateStateWithProps(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.updateStateWithProps(nextProps);
    }

    deAuthentication = () => {
        window.sessionStorage.removeItem('CSSAuthenticated');
        window.sessionStorage.removeItem('applicantID');
        DeAuthenticationService.logout().then();
        this.props.history.push({ pathname: PageEnum.Authentication });
    }

    updateStateWithProps = (theProps) => {
        const history = createBrowserHistory();

        if(theProps.hasOwnProperty("location")){
            history.push({ pathname: PageEnum.Authentication.url });
            this.setState(function(){
                return {
                    dayText: theProps.location.state.hasOwnProperty("dayText") ? theProps.location.state.dayText : '',
                    time: theProps.location.state.hasOwnProperty("time") ? theProps.location.state.time : '',
                    locationLine1: theProps.location.state.hasOwnProperty("locationLine1") ? theProps.location.state.locationLine1 : '',
                    locationLine2: theProps.location.state.hasOwnProperty("locationLine2") ? theProps.location.state.locationLine2 : '',
                    employmentType: theProps.location.state.hasOwnProperty("employmentType") ? theProps.location.state.employmentType : '',
                    position: theProps.location.state.hasOwnProperty("position") ? theProps.location.state.position : '',
                    drugTestRequired: theProps.location.state.hasOwnProperty("drugTestRequired") ? theProps.location.state.drugTestRequired : false,
                    bgcRequired: theProps.location.state.hasOwnProperty("bgcRequired") ? theProps.location.state.bgcRequired : false,
                    timeSlot: theProps.location.state.hasOwnProperty("timeSlot") ? theProps.location.state.timeSlot : '',
                    reqnNbr: theProps.location.state.hasOwnProperty("reqnNbr") ? theProps.location.state.reqnNbr : '',
                    scheduleDays: theProps.location.state.hasOwnProperty("scheduleDays") ? theProps.location.state.scheduleDays : '',
                    scheduleTimes: theProps.location.state.hasOwnProperty("scheduleTimes") ? theProps.location.state.scheduleTimes : '',
                    wage: theProps.location.state.hasOwnProperty("wage") ? theProps.location.state.wage : '',
                    rescheduled: theProps.location.state.hasOwnProperty("rescheduled") ? theProps.location.state.rescheduled : false,
                    availability: theProps.location.state.hasOwnProperty("availability") ? theProps.location.state.availability : true,
                    timeLeftToReschedule:theProps.location.state.hasOwnProperty("timeLeftToReschedule") ? theProps.location.state.timeLeftToReschedule : true,
                    earlyClose: theProps.location.state.hasOwnProperty("earlyClose") ? theProps.location.state.earlyClose : false,
                    locationType: theProps.location.state.hasOwnProperty("locationType") ? theProps.location.state.locationType : '',
                    accommodation: theProps.location.state.hasOwnProperty("accommodation") ? theProps.location.state.accommodation : false,
                    eventAddress1: theProps.location.state.hasOwnProperty("eventAddress1") ? theProps.location.state.eventAddress1 : '',
                    eventAddress2: theProps.location.state.hasOwnProperty("eventAddress2") ? theProps.location.state.eventAddress2 : '',
                    eventAddress3: theProps.location.state.hasOwnProperty("eventAddress3") ? theProps.location.state.eventAddress3 : ''

                }
            });
        } else if(theProps.hasOwnProperty("dayText")){
            history.push({ pathname: PageEnum.Authentication.url });
            this.setState(function(){
                return {
                    dayText: theProps.hasOwnProperty("dayText") ? theProps.dayText : '',
                    time: theProps.hasOwnProperty("time") ? theProps.time : '',
                    locationLine1: theProps.hasOwnProperty("locationLine1") ? theProps.locationLine1 : '',
                    locationLine2: theProps.hasOwnProperty("locationLine2") ? theProps.locationLine2 : '',
                    bgcRequired: theProps.hasOwnProperty("bgcRequired") ? theProps.bgcRequired : false,
                    drugTestRequired: theProps.hasOwnProperty("drugTestRequired") ? theProps.drugTestRequired : false,
                    employmentType: theProps.hasOwnProperty("employmentType") ? theProps.employmentType : false,
                    position: theProps.hasOwnProperty("position") ? theProps.position : '',
                    timeSlot: theProps.hasOwnProperty("timeSlot") ? theProps.timeSlot : '',
                    reqnNbr: theProps.hasOwnProperty("reqnNbr") ? theProps.reqnNbr : '',
                    scheduleDays: theProps.hasOwnProperty("scheduleDays") ? theProps.scheduleDays : '',
                    scheduleTimes: theProps.hasOwnProperty("scheduleTimes") ? theProps.scheduleTimes : '',
                    wage: theProps.hasOwnProperty("wage") ? theProps.wage : '',
                    rescheduled: theProps.hasOwnProperty("rescheduled") ? theProps.rescheduled : false,
                    availability: theProps.hasOwnProperty("availability") ? theProps.availability : true,
                    timeLeftToReschedule: theProps.hasOwnProperty("timeLeftToReschedule") ? theProps.timeLeftToReschedule : true,
                    earlyClose: theProps.hasOwnProperty("earlyClose") ? theProps.earlyClose : true,
                    locationType: theProps.hasOwnProperty("locationType") ? theProps.locationType : '',
                    accommodation: theProps.hasOwnProperty("accommodation") ? theProps.accommodation : false,
                    eventAddress1: theProps.hasOwnProperty("eventAddress1") ? theProps.eventAddress1 : '',
                    eventAddress2: theProps.hasOwnProperty("eventAddress2") ? theProps.eventAddress2 : '',
                    eventAddress3: theProps.hasOwnProperty("eventAddress3") ? theProps.eventAddress3 : ''
                }
            });
        }
    }

    logoutHandler = () => {

        this.deAuthentication();
        this.props.history.push({pathname:PageEnum.Authentication.url});
    }

    rescheduleHandler = () => {
        this.props.history.push({ pathname: PageEnum.Calendar.url, search: '?reschedule=true&offer=true' });
    }
    
    cancelHandler = () => {
        var applicantID = window.sessionStorage.getItem('applicantID');
        SchedulingService.cancelOffer(this.state.reqnNbr, applicantID,this.state.timeSlot).then(this.cancelOnComplete).catch(() => {
            this.setError('Error processing data.  Please try again.');
        });
    }

    cancelOnComplete = () => {
        this.props.history.push({pathname: PageEnum.TellUsWhy.url});
    }

    render () {
        var {rescheduled, availability,timeLeftToReschedule, bgcRequired, drugTestRequired, accommodation} = this.state;

        return (
            <div id='header'>
                <Row>
                    <Col className={"col-12"}>
                        {(!rescheduled || accommodation) && <h2 className={"infoHeader"}>Congratulations! You’re one step closer to your orange apron!</h2>}
                        {(rescheduled && !accommodation) && <div><h2>Reschedule confirmed</h2><p>You have been rescheduled.</p></div>
                        }
                    </Col>
                </Row>

                <PositionDetails dayText={this.state.dayText} time={this.state.time}
                                  employmentType={this.state.employmentType} jobDescription={this.state.position}
                                  pay={this.state.wage}
                                  days={this.state.scheduleDays} times={this.state.scheduleTimes}
                                  addressLine1={this.state.locationLine1} addressLine2={this.state.locationLine2}
                                  eventAddress1={this.state.eventAddress1} eventAddress2={this.state.eventAddress2} eventAddress3={this.state.eventAddress3}/>
                <Row><Col></Col></Row>
                <Row>
                    <Col className={"col-12"}>
                        <p>Please check your email for additional information related to your on-site visit.</p>
                        {(!rescheduled && !accommodation) && <p>If you are unable to attend your scheduled time, you have a one-time opportunity to reschedule your on-site visit to complete your activities.</p>}
                        {(!accommodation && timeLeftToReschedule) && <p>By selecting 'No Longer Interested', you will no longer be considered for this position.</p>}
                        {accommodation && <p>If you are unable to attend your {rescheduled && <span>re</span>}scheduled time, please dial at 1-800-910-6704, ext 20902 to leave a message for the Talent Acquisition Center. Please leave your name, your Candidate ID (refer to email) and state
                            {!rescheduled && <span> whether you wish to reschedule or cancel. A member of the Talent Acquisition Center team will respond to you within 24-48 hours.</span>}
                            {rescheduled && <span>  that you wish to cancel your appointment. A member of the Talent Acquisition Center team will cancel the appointment and notify the appropriate parties.</span>}
                        </p>}
                        {(accommodation && rescheduled) &&<p>By cancelling this appointment, you will no longer be considered for this position. </p>}
                        <p>Reminder – this offer is contingent based on:
                            <ul className={"bulletPoints"}>
                                {bgcRequired && <li> Background check</li>}
                                {drugTestRequired && <li> Drug test</li>}
                                <li> Review and agree to perform the essential functions of the job with or without a reasonable accommodation</li>
                                <li> Review and agree to The Home Depot standards of performance</li>
                                <li> Prior Home Depot associates – this offer is also contingent upon your rehire eligibility status with the company</li>
                            </ul>
                        </p>
                        <p>See you soon!</p>
                     </Col>
                </Row>

                {(!rescheduled && !availability && timeLeftToReschedule) && <Row>
                    <Col className={"col-12"}>
                        <br/><b>Please Note:</b> Currently, this location does not have any timeslots available for rescheduling an appointment. Check back at a later time to see if timeslots have been added. <br/><br/>  You have up until 2 hours before your initial appointment to reschedule through Candidate Self Service.
                    </Col>
                </Row>}
                {(!timeLeftToReschedule)&& <Row>
                    <Col className={"col-12"}>
                        <br/><b>Please Note:</b> We are unable to {!rescheduled && <span>reschedule or</span>} cancel your appointment since it is 2 hours or less until your scheduled time. Contact the location at the number listed above to {!rescheduled && <span>reschedule or </span>} cancel your appointment.<br/><br/>
                    </Col>
                </Row>}
                {/* {modalContent} */}
                <div>
                    <Row className="bigScreenVerticalMargins pushLeft">
                        <Col sm={12} md={4} className="largeVerticalMargins">
                            <button id="butConfirmation" className="button primary submitbutton bsize" type="button" onClick={this.logoutHandler}>Log-out</button>
                        </Col>
                        {!accommodation && <Col sm={12} md={4}>

                            <button hidden={rescheduled || !timeLeftToReschedule} disabled={!availability || !timeLeftToReschedule} className="button submitbutton reschedule" type="button" id="rescheduleBtn" onClick={this.rescheduleHandler}>Reschedule Visit</button>
                        </Col> }
                        {!accommodation && <Col sm={12} md={4} className={!rescheduled ? 'largeVerticalMargins':''}>
                            <button  hidden={!timeLeftToReschedule} disabled={!timeLeftToReschedule} className="button submitbutton nli" type="button" id="cancelBtn" onClick={this.cancelHandler}>No Longer<br className="largeViewBreak"/> Interested</button>
                        </Col>}
                    </Row>
                </div>
            </div>
        );
    }
}

export default OfferConfirmedDetails;