import React from "react";
import {Col, Row} from "react-bootstrap";
import RequisitionService from "../Services/RequisitionService";
import SchedulingService from "../Services/SchedulingService";
import PageEnum from "../Util/PageEnum";

class DeclineReasonSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            declineReasons:[]
        }
        this.clickEventHandler = this.clickEventHandler.bind(this);

    }

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.hasOwnProperty("exclude")){
            RequisitionService.getDeclineReasons(this.props.location.state.exclude).then(this.getDeclineReasonsOnComplete);

        }else RequisitionService.getDeclineReasons(false).then(this.getDeclineReasonsOnComplete);
    }



    getDeclineReasonsOnComplete = (resp) => {
            if(resp.status === 200 && resp.data){
                this.setState(function(){
                    return {
                        declineReasons:resp.data
                    }
                });
            } else {
                this.setState(function() {
                    return {
                        errorMessage: "Error retrieving declineReasons"
                    }
                })
            }

    }

    updateDeclineReasonOnComplete = (resp) => {
        if(resp.data.rowsUpdated === 1){
            this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=23"});
        }else {
            console.log("An error has occurred");
        }
    }

    clickEventHandler = (reason) => {
        var applicantID = window.sessionStorage.getItem('applicantID');
        SchedulingService.updateDeclineReason(applicantID, reason.code).then(this.updateDeclineReasonOnComplete).catch(() => {
            this.setState(function() {
                return {errorMessage : 'Error processing data.  Please try again.'}
            });
            })
    }



    render() {
        return (
            <div>
                <Row>
                    <Col sm={12} >
                        <div>
                            <h2>No longer interested?</h2>
                            <p>Please let us know why you declined this offer.</p>
                        </div>
                    </Col>
                </Row>
                    <Row>
                        <Col className='bigScreenVerticalMargins declineListMargin' >
                        {(this.state.declineReasons) ? this.state.declineReasons.map((reason) => {
                            return (
                                <button sm={6} md={2}  key={reason.code} className="declineBtn button" onClick={() => this.clickEventHandler(reason)}>{reason.description}</button>
                            )})
                        : ""}
                    </Col>
                </Row>

            </div>
        )
    }
}

export default DeclineReasonSelection;