import React from "react";
import {InfoCircleFilled} from '@ant-design/icons';
import {Col, Row} from "antd";

const InfoModal = ({show, eventLocation, eventAddress, eventCity, eventZipCode, eventState, handleClose}) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (
        <div className={showHideClassName}>
            <div className="modal-dialog col-12">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"><InfoCircleFilled className="info-circle"/> &nbsp;&nbsp;Please Note</h5>
                    </div>
                    <div className="modal-body">
                        <Row><Col>The next step will be held at a different location</Col></Row>
                        <Row><Col>than where you'll be employed.</Col></Row>
                        <br/>
                        <Row><Col>
                            {eventLocation}
                        </Col></Row>
                            <Row><Col>
                                {eventAddress}<br/>
                                {eventCity} {eventState} {eventZipCode}</Col></Row>
                        <div className="modal-footer">
                            <button type="button" className="button primary" onClick={handleClose}>I Understand</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;