import React, { Component } from 'react';
import Header from './components/Header';
import Questions from './components/Questions';
import logo from './img/thd-logo.png';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import BasicAuthentication from './components/BasicAuthentication';
import Message from './components/Message';
import StillInterested from './components/StillInterested';
import Calendar from './components/Calendar';
import CalendarConfirmation from './components/CalendarConfirmation';
import CalendarConfirmed from './components/CalendarConfirmed';
import InterviewConfirmed from './components/InterviewConfirmed';
import MinimumAge from './components/MinimumAge';
import Offer from './components/Offer';
import NextSteps from "./components/NextSteps";
import ErrorBanner from "./components/ErrorBanner";
import Leader from "./components/Leader";
import Schedule from "./components/Schedule";
import ScheduleAccommodation from "./components/ScheduleAccommodation";
import OfferConfirmed from "./components/OfferConfirmed";
import DeclineReasonSelection from "./components/DeclineReasonSelection";
import OfferConfirmation from "./components/OfferConfirmation";
// Added for Job recommendation changes
import AlternateRequisition from "./components/AlternateRequisition";

class App extends Component {

  componentDidMount() {
      document.title = "Candidate Self Screening";
  }
  
  render() {
    return (
      <div>
        <Grid bsClass="container header">
          <Header title='Candidate Self Service' imgSrc={logo} />
        </Grid>
        <Grid bsClass="container main">
          <Router>
            <div>
              <Route exact path='/' component={BasicAuthentication} />
              <Route exact path='/authenticate' component={BasicAuthentication} />
              <Route exact path="/signin" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <Questions {...props}/> : <Redirect to="/authenticate"/>)}/>
              <Route exact path="/questions" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <Questions {...props}/> : <Redirect to="/authenticate"/>)}/>
              <Route path="/message" component={Message} />
              <Route path="/stillInterested" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <StillInterested {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route exact path="/calendar" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <Calendar {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route exact path="/calendar/confirmation" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <CalendarConfirmation {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route exact path="/calendar/confirmed" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <CalendarConfirmed {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route exact path="/interview/confirmed" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <InterviewConfirmed {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/minimumAge"  render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <MinimumAge {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/offer/info"  render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <Offer {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/nextSteps" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <NextSteps {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/leader" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <Leader {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/offer/confirmation" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <OfferConfirmation {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/offer/confirmed" render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <OfferConfirmed {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/tellUsWhy"  render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <DeclineReasonSelection {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/schedule/shifts"  render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <Schedule {...props}/> : <Redirect to="/authenticate"/>)} />
              <Route path="/schedule/accommodation"  render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <ScheduleAccommodation {...props}/> : <Redirect to="/authenticate"/>)} />
              {/*Added for Job Recommendation changes*/}
              <Route path="/alternateRequisition"  render={(props) => (window.sessionStorage.getItem('CSSAuthenticated') ? <AlternateRequisition {...props}/> : <Redirect to="/authenticate"/>)} />
              {/*<Route path="/alternateRequisition" component={AlternateRequisition} />*/}

            </div>
          </Router>
        </Grid>
      </div>
    );
  }
}

export default App;