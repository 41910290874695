import axios from 'axios';

import redirectService from "./RedirectService";
import CacheBuster from "../Util/CacheBuster";


var QuestionService = {
    updatePhoneScreenQuestionFlag:function(applicantId, questionCode, answer){
         return axios.put('/api/questions/updateFlag/'+ questionCode + '/' + answer, '', {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
            }).catch(e => redirectService.redirect(e));
    },updatePhoneScreenQuestionFlagAndCodesBatch:function(data){
        return axios.put('/api/questions/updateFlagAndCode/batch?id='+data.applicantId , data, {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getStillInterestedQuestion:function(applicantId){
        return axios.get('/api/questions/stillInterested?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getQuestions:function(applicantId){
        return axios.get('/api/questions/' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getMinimumAgeQuestion:function(applicantId){
        return axios.get('/api/questions/minimumAge?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"
}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getQuestionById:function(questionId){
        return axios.get('/api/questions/id/' + questionId, {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    }

}
export default QuestionService;




