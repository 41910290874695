import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StopOutlined, CloseOutlined } from '@ant-design/icons';


function ErrorBanner(props){
    return (
        <div className='row no-pad'>
                {props.submitErrorMessage ? <div className="banner">
                <StopOutlined style={{
                    fontSize: "28px",
                    color: "red",
                    strokeWidth: "15", // --> higher value === more thickness the filled area
                    stroke: "red"
                }} className={"col-1"} aria-hidden="true"/>
                <div className="col-10" dangerouslySetInnerHTML={{ __html: props.submitErrorMessage }}></div>
                <CloseOutlined style={{
                    fontSize: "14px",
                    color: "red",
                    strokeWidth: "140", // --> higher value === more thickness the filled area
                    stroke: "red"}} className="col-1" aria-hidden="true" onClick={props.closeEventHandler}/>
                </div> : "" }
        </div>
    )
}

ErrorBanner.defaultProps = {
    submitErrorMessage: ''
}

ErrorBanner.propTypes = {
    submitErrorMessage: PropTypes.string,
    closeEventHandler: PropTypes.func.isRequired 
};

export default ErrorBanner;