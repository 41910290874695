import React from "react";
import {Col, Row} from "react-bootstrap";
import PageEnum from "../Util/PageEnum";
import ReactPlayer from 'react-player/vimeo'




class Leader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            enough:false,
            playedSeconds:0,
            playing:true,
            isButtonDisabled:true
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ isButtonDisabled: false }), 7000);

    }

    yesClickHandler = () => {
            this.props.history.push({pathname: PageEnum.Offer.url});
    }

    ref = player => {
        this.player = player
    }
    handleDuration = (duration) => {
        this.setState({ duration })
    }

    handleProgress = state => {
            this.setState(state)
            // if(state.playedSeconds > 5 ){
            //     this.setState(function() {
            //         return {
            //             enough:true
            //         }
            //     });
            // }
    }



    render() {
        let {enough, isButtonDisabled, playing} = this.state;

        return (
            <div>
                <Row>
                    <br/>
                    <Col className='col-12'>
                        <div className="aspect-ratio" >
                            {/*{<Iframe url="https://player.vimeo.com/video/522973157" width="100%" height="100%"/>}*/}
                            {<ReactPlayer ref={this.ref} url="https://player.vimeo.com/video/522973157" controls={true} onDuration={this.handleDuration} onProgress={this.handleProgress}
                                          playing={playing}
                                          width="100%" height="100%"/>}



                        </div>

                    </Col>


                </Row>
                <Row className='topMargin'>
                    <Col xs sm ={12} md={8} className="largeVerticalMargins">
                        <button  className="button submitbutton bsize Big primary" type="button" disabled={isButtonDisabled} onClick={this.yesClickHandler} >
                            Next
                        </button>
                    </Col>

                </Row>
            </div>
        )
    }
}

export default Leader;