import React from 'react';
import SchedulingService from '../Services/SchedulingService';
import CalendarConfirmed from './CalendarConfirmed';
import ErrorBanner from './ErrorBanner';
import Time from '../Util/Time';

class InterviewConfirmed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dayText: '',
            time: '',
            locationLine1: '',
            locationLine2: '',
            submitErrorMessage: '',
            phoneNumber: '',
            requisition: '',
            timeSlot: '',
            rescheduled: false,
            availability: true,
            timeLeftToReschedule: true,
            earlyClose: false,
            locationType: "STR"

        }
    }

    UNSAFE_componentWillMount() {
        this.getScheduledInterviewInfo();
    }

    getScheduledInterviewInfo = () => {
        var applicantID = window.sessionStorage.getItem('applicantID');
        SchedulingService.getScheduledInterviewInfo(applicantID).then(this.getScheduledInterviewInfoOnComplete);
    }

    getScheduledInterviewInfoOnComplete = (resp) => {
        if(resp.data.hasOwnProperty("dayText")){
            //window.sessionStorage.removeItem('applicantID');
            this.setState(function() {
                return {
                    dayText: resp.data.dayText,
                    time: Time.getTimeFromTimeString(resp.data.time),
                    locationLine1: resp.data.addressLine1,
                    locationLine2: resp.data.addressLine2,
                    phoneNumber: resp.data.phoneNumber,
                    position: resp.data.position,
                    requisition: resp.data.requisition,
                    timeSlot: resp.data.timeSlot,
                    rescheduled: resp.data.rescheduled,
                    availability: resp.data.availability,
                    timeLeftToReschedule:resp.data.timeLeftToReschedule,
                    earlyClose: resp.data.earlyClose,
                    locationType: resp.data.locationType
                }
            });
        } else {
            this.setState(function() {
                return {
                    submitErrorMessage:'Not able to retreive your interview information.'
                }
            });
        }
    }

    closeEventHandler = () => {
        this.setState(function() {
            return {
                submitErrorMessage:''
            }
        })
    }

    render () {
        return (
            <div>
                <ErrorBanner submitErrorMessage={this.state.submitErrorMessage} closeEventHandler={this.closeEventHandler} />
                <CalendarConfirmed history={this.props.history} dayText={this.state.dayText} time={this.state.time} locationLine1={this.state.locationLine1} locationLine2={this.state.locationLine2} phoneNumber={this.state.phoneNumber} showNumber={false} position={this.state.position} reqnNbr ={this.state.requisition} timeSlot={this.state.timeSlot} rescheduled={this.state.rescheduled} availability={this.state.availability} timeLeftToReschedule={this.state.timeLeftToReschedule} earlyClose={this.state.earlyClose} locationType={this.state.locationType}/>
            </div>
        );
    }
}

export default InterviewConfirmed;