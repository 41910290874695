import axios from 'axios';

import redirectService from "./RedirectService";
import CacheBuster from "../Util/CacheBuster";


var RequisitionService = {
    getRequisitionNumberByApplId:function(applId){
        return axios.get('/api/requisition/getNumber?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getRequisitionDetailsByApplId:function(applId){
        return axios.get('/api/requisition/detailsByApplicant?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getCalendarDetails: function() {
        return axios.get('/api/requisition/calendar/eventDetails?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e))
    },
    getHiringEventDetails:function(reqnId) {
        return axios.get('/api/requisition/calendar/hiringEventDetails?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e))
    },
    getPositionDetails:function(applId){
        return axios.get('/api/requisition/positionDetails?cb=' +CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
                    return response;

    }).catch(e => redirectService.redirect(e))
    },
    getDeclineReasons:function(exclude){
        return axios.get('/api/requisition/declineReasons'+(exclude ? exclude:''), {headers: { "Content-type":"application/json"}}).then(function(response){
                    return response;
    }).catch(e => redirectService.redirect(e))
    },
    getShiftsByApplicantId:function(applId){
    return axios.get('/api/requisition/shiftsByApplicantId?cb='+ CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
        return response;
    }).catch(e => redirectService.redirect(e))
    },
//     Added for job recommendation changes

    getAltReqByApplicantId:function(applId){
        return axios.get('/api/requisition/alternateRecommendations?cb='+ CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e))
    } ,


    updateAltReqNotInterested: function (applId) {
    return axios.put('/api/requisition/altReqNotInterested' ,'',{headers: { "Content-type":"application/json"}}).then(function (response) {
        return response;
    }).catch(e => redirectService.redirect(e));
} ,


    moveCandidateToAltReq: function (applId, currReq, altReq, currStr) {
        return axios.post('/api/requisition/altReqMove/' + currReq + '/' + altReq + '/' + currStr ,'',{headers: { "Content-type":"application/json"}}).then(function (response) {
            return response;
        }).catch(e => redirectService.redirect(e));
    } ,

    //Added for Contingent Offer additional BCG verbiage
    getCoBcgStrByApplicantId:function(applId){
        return axios.get('/api/requisition/coSpecBcgStrByApplicantId?cb='+ CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e))
    }
}

export default RequisitionService;