import React from "react";
import {Col, Row} from "react-bootstrap";
import RequisitionService from "../Services/RequisitionService";
import CandidateStatusService from "../Services/CandidateStatusService";
import PageEnum from "../Util/PageEnum";
import SchedulingService from "../Services/SchedulingService";
import QuestionService from "../Services/QuestionService";
import DeclineModal from "./DeclineModal";
import ConfirmModal from "./ConfirmModal";
import InfoModal from "./InfoModal";

class NextSteps extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bgcRequired: false,
            drugTestRequired: false,
            requisitionId: null,
            loaded: false,
            showDecline: false,
            showAssist: false,
            showInfoModal: false,
            eventLocation: '',
            eventAddress: '',
            eventCity: '',
            eventZipCode: '',
            eventState: '',
            isU18State: false,
            isU18JobTitleCode: false,
            //Added for Contingent Offer additional BCG verbiage
            isCoBcgStrFlg: 'N'

        }
    }

    async componentDidMount() {

      const coBcgStrFlg = await RequisitionService.getCoBcgStrByApplicantId(window.sessionStorage.getItem('applicantID')).then(resp => {
          return resp;
      });

     this.setState(function () {
            return {
                isCoBcgStrFlg: coBcgStrFlg.data
            }
     });
        // console.log("isCoBcgStrFlg : " + this.state.isCoBcgStrFlg);

        if (this.props.location.state) {
            await this.updateStateWithProps(this.props);
        } else {
            await this.getPositionAndCalDetails();
        }
    }

    getPositionAndCalDetails = async () => {
        let applicantID = window.sessionStorage.getItem('applicantID');
        const positionDetailsResponse = await RequisitionService.getPositionDetails(applicantID).then(resp => {
            return resp;
        });
        await this.updateStateWithDetails(positionDetailsResponse);
    }

    updateStateWithDetails = async (positionDetailsResponse) => {
        const calDetailsResponse = await RequisitionService.getCalendarDetails().then(resp => {
            return resp;
        });
        this.setState(function () {
            return {
                bgcRequired: positionDetailsResponse.data.bgcRequired,
                drugTestRequired: positionDetailsResponse.data.drugTestRequired,
                requisitionId: positionDetailsResponse.data.requisitionId,
                isU18State: positionDetailsResponse.data.u18State,
                isU18JobTitleCode: positionDetailsResponse.data.u18JobTitleCode,
                showInfoModal: calDetailsResponse.data.reqCalendarTypeCd === '20',
                eventLocation: calDetailsResponse.data.eventLocation,
                eventAddress: calDetailsResponse.data.eventAddress,
                eventCity: calDetailsResponse.data.eventCity,
                eventZipCode: calDetailsResponse.data.eventZipCode,
                eventState: calDetailsResponse.data.eventState,
                loaded: true,
            }
        });
    }

    updateStateWithProps = async (theProps) => {
        let bgcRequired = '';
        let drugTestRequired = '';
        let reqId = '';
        let isU18State;
        let isU18JobTitleCode;
        if (theProps.hasOwnProperty("location")) {
            bgcRequired = theProps.location.state.hasOwnProperty("bgcRequired") ? theProps.location.state.bgcRequired : false;
            drugTestRequired = theProps.location.state.hasOwnProperty("drugTestRequired") ? theProps.location.state.drugTestRequired : false;
            reqId = theProps.location.state.hasOwnProperty("requisitionId") ? theProps.location.state.requisitionId : null;
            isU18State =  theProps.location.state.hasOwnProperty("isU18State") ? theProps.location.state.isU18State : false;
            isU18JobTitleCode =  theProps.location.state.hasOwnProperty("isU18JobTitleCode") ? theProps.location.state.isU18JobTitleCode : false;
        } else if (theProps.hasOwnProperty("drugTestRequired")) {
            bgcRequired = theProps.hasOwnProperty("bgcRequired") ? theProps.bgcRequired : false;
            drugTestRequired = theProps.hasOwnProperty("drugTestRequired") ? theProps.drugTestRequired : false;
            reqId = theProps.hasOwnProperty("requisitionId") ? theProps.requisitionId : null;
            isU18State =  theProps.hasOwnProperty("isU18State") ? theProps.isU18State : false;
            isU18JobTitleCode =  theProps.hasOwnProperty("isU18JobTitleCode") ? theProps.isU18JobTitleCode : false;
        }

        const calDetailsResponse = await RequisitionService.getCalendarDetails().then(resp => {
            return resp;
        });
        this.setState(function () {
            return {
                bgcRequired: bgcRequired,
                drugTestRequired: drugTestRequired,
                requisitionId: reqId,
                isU18State: isU18State,
                isU18JobTitleCode: isU18JobTitleCode,
                showInfoModal: calDetailsResponse.data.reqCalendarTypeCd === '20',
                eventLocation: calDetailsResponse.data.eventLocation,
                eventAddress: calDetailsResponse.data.eventAddress,
                eventCity: calDetailsResponse.data.eventCity,
                eventZipCode: calDetailsResponse.data.eventZipCode,
                eventState: calDetailsResponse.data.eventState,
                loaded: true
            }
        });
    }

    handleLink = () => {
        this.openAssistModal();
    }

    yesClickHandler = () => {
        this.setState({loaded: false});
        SchedulingService.getRequisitionAvailability(this.state.requisitionId, true).then(this.availabilityOnComplete)
    }

    availabilityOnComplete = (resp) => {
        if(resp && resp.data){
            if (resp.data.needCandidates === false){
                CandidateStatusService.updateTacOfferStatus(this.state.requisitionId, 8).then(this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=25"}));
            }else if (resp.data.calendarAvailability === false){
                CandidateStatusService.updateTacOfferStatus(this.state.requisitionId, 2).then(this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=25"}));
            }else{
                QuestionService.updatePhoneScreenQuestionFlag(window.sessionStorage.getItem('applicantID'), 26,"N").then(this.updateQuestionOnComplete)
            }
        }
    }

    updateQuestionOnComplete = (resp) => {

        if (resp && resp.data && resp.data.rowsUpdated === 1) {
            CandidateStatusService.updateTacOfferStatus(this.state.requisitionId, 7).then(this.updatePendingSchedulingOnComplete);
        } else {
            this.setState({loaded: true});
            //TODO no or bad response
        }
    }

    updatePendingSchedulingOnComplete = (resp) => {
        if ((resp.data.rowsUpdated === 1)) {
            this.props.history.push({pathname: PageEnum.Calendar.url, search: "?offer=true"});
        } else {
            this.setState({loaded: true});
            //TODO no or bad response
        }
    }

    accommodateOnComplete = (resp) => {
        if ((resp.data.emailSent === 1) && (resp.data.updates === 3)) {
            this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=22"});
        } else {
            this.setState({loaded: true});
            //TODO no or bad response
        }
    }

    noClickHandler = () => {
        let applicantID = window.sessionStorage.getItem('applicantID');
        let reqId = this.state.requisitionId;
        this.setState({loaded: false});
        CandidateStatusService.updateTacOfferStatus(reqId, 3).then(this.updateTacOfferStatusOnComplete);
    }

    updateTacOfferStatusOnComplete = (resp) => {
        if (resp.data.rowsUpdated === 2) {
            this.props.history.push({pathname: PageEnum.TellUsWhy.url});
        } else {
            this.setState({loaded: true});
            //TODO no or bad response
        }

        this.setState({loaded: true});

    }

    openDeclineModal = () => {
        this.setState({
            showDecline: true
        });
    }

    closeDeclineModal = () => {
        this.setState({
            showDecline: false
        });
    }

    openAssistModal = () => {
        this.setState({
            showAssist: true
        });
    }

    closeAssistModal = () => {
        this.setState({
            showAssist: false
        });
    }

    yesAssistHandler = () => {
        this.setState({loaded: false});
        SchedulingService.updateAccommodation(this.state.requisitionId, window.sessionStorage.getItem('applicantID')).then(this.accommodateOnComplete);

        this.setState({
            showAssist: false
        });
    }
    handleCloseInfoModal = () => {
        this.setState({showInfoModal: false});
    }

    noAssistHandler = () => {
        this.closeAssistModal();
    }

    render() {
        let {loaded, bgcRequired, drugTestRequired, showInfoModal, eventLocation, eventAddress, eventCity, eventZipCode, eventState,
        isU18State, isU18JobTitleCode,isCoBcgStrFlg} = this.state;
        // console.log("Let isCoBcgStrFlg : " + isCoBcgStrFlg);
        return (
            <div>
                {loaded ? (
                    <div>
                        <InfoModal show={showInfoModal} eventLocation={eventLocation} eventAddress={eventAddress} eventCity={eventCity} eventZipCode={eventZipCode}
                                   eventState={eventState} handleClose={this.handleCloseInfoModal}/>
                        <Row>
                            <Col className='col-12'>
                                <h2 class={"infoHeader"}>Glad you’re still interested – We’d like to extend a contingent job offer to you!</h2>
                                <p>This offer is contingent on meeting with a Home Depot associate to complete the following:</p><br/>
                                <ul className={'bulletList'}>
                                    <li>Personal information form (Social Security number required)</li>
                                    {/*Commented as part of removing Drug test*/}
                                    {/*{drugTestRequired &&*/}
                                    {/*    <li>Drug test</li>}*/}
                                    <li>Review and agree to perform the essential functions of the job with or
                                        without a reasonable accommodation
                                    </li>
                                    <li>Review and agree to The Home Depot standards of performance</li>
                                    {/*Added for Contingent Offer additional BCG verbiage S2-1774*/}
                                    {/*<li>Prior Home Depot Associates - this offer is also contingent upon your rehire*/}
                                    {/*    eligibility status with the company.*/}
                                    {/*</li>*/}
                                    <li>Rehire eligibility status if you are a prior Home Depot Associate. </li>

                                        {bgcRequired && <li>Background check</li>}
                                        {bgcRequired && isCoBcgStrFlg === 'Y' &&
                                            <p><br/> <h6><i> The Home Depot conducts a review of an Applicant's or
                                                Associate's criminal history in connection with a
                                                conditional offer of employment. The Home Depot reasonably believes that
                                                an Applicant's or Associate's criminal
                                                history may have a direct, adverse, and negative relationship with
                                                respect to the material job duties of this
                                                specific position (including, but not limited to,
                                                providing a safe work and shopping environment
                                                and engaging in safe interactions with associates and customers).
                                                Therefore, an Applicant's or Associate's
                                                criminal history will potentially result in the withdrawal of a
                                                conditional offer of employment.  </i></h6></p>
                                        }
                                </ul>
                                <br/>
                                {isU18State && !isU18JobTitleCode && <p>If you are under the age of 18, you are not eligible for hire for this position.</p>}
                                {isU18State && isU18JobTitleCode &&  <p>If you are under the age of 18, you must have a parent or guardian present and
                                        they must be willing to sign documents on your behalf.</p>}
                                {!isU18State && <p>If you are under the age of 18, you are not eligible for hire at this location.</p>}
                            </Col>
                        </Row>
                <Row></Row>
                <Row>
                    <Col className='col-12'>
                        Select 'Continue' to schedule your onsite visit.
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className="col-12 aslLink">
                        <a onClick={this.handleLink}>American Sign Language (ASL) needed</a>
                    </Col>
                </Row>

                <Row className='topMargin'>
                    <Col sm={12} md={8} className="largeVerticalMargins">
                        <button  className="button submitbutton bsize Big primary" type="button" onClick={this.yesClickHandler}>
                            Continue
                        </button>
                    </Col>
                    <Col sm={12} md={3} >
                        <button  className="button submitbutton bsizeSmall" type="button" onClick={this.openDeclineModal}>
                            Decline Offer
                        </button>
                    </Col>
                </Row>
                <DeclineModal handleAccept={this.noClickHandler} handleClose={this.closeDeclineModal} show={this.state.showDecline}/>
                <ConfirmModal handleAccept={this.yesAssistHandler} handleClose={this.noAssistHandler} show={this.state.showAssist}><p>You are about to request a hearing impairment interpreter for your onsite visit.</p>Are you sure you need a sign language interpreter?</ConfirmModal>



                    </div>) : <div className="loader">Loading...</div>}</div>
        );
    }
}


export default NextSteps;