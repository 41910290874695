import axios from 'axios';
import redirectService from "./RedirectService";
import CacheBuster from "../Util/CacheBuster";

var UpdateAndRoutingService = {

    updateAndGetRouteAuthentication:function(sms){
        return axios.put('/api/UpdateAndRoute/authentication?cb=' + CacheBuster.generateRandomString()+(sms ? ('&sms='+sms) : ''),'',{headers: { "Content-type":"application/json",
            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    updateAndRouteQuestions:function(applId){
        return axios.put('/api/UpdateAndRoute/questions?cb=' + CacheBuster.generateRandomString(),'', {headers: { "Content-type":"application/json",
            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    }
}

export default UpdateAndRoutingService;