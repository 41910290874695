import axios from 'axios';
import redirectService from "./RedirectService";
import CacheBuster from "../Util/CacheBuster";


var MessageService = {
    getMessage: function(messageId, applId){
        return axios.get('/api/message/' + messageId + '?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json"
            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    }
}

export default MessageService;