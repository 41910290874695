import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SchedulingService from '../Services/SchedulingService';
import StoreDetailsService from '../Services/StoreDetailsService';
import CalendarDaySlots from './CalendarDaySlots';
import PageEnum from '../Util/PageEnum';
import Time from '../Util/Time';
import ErrorBanner from '../components/ErrorBanner';
import RequisitionService from '../Services/RequisitionService';
import PhoneScreenService from '../Services/PhoneScreenService';
import queryString from "query-string";
import UpdateAndRoutingService from "../Services/UpdateAndRoutingService";
import JobDetailsService from "../Services/JobDetailsService";
import CandidateStatusService from "../Services/CandidateStatusService";
import InfoModal from "./InfoModal";

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        let reschedule = false;
        let offer = false;
        if(queryString.parse(props.location.search).reschedule){
            reschedule = (queryString.parse(props.location.search).reschedule == 'true');
        }
        if(queryString.parse(props.location.search).offer){
            offer = (queryString.parse(props.location.search).offer == 'true')
        }

        this.state = {
            reqnId: '',
            availableSlots: [],
            storeNumber: '',
            errorMessage: '',
            loaded: false,
            reschedule: reschedule,
            position: '',
            selected1: -1,
            selected2: -1,
            dayText: '',
            time: '',
            showExtra: false,
            offer: offer,
            eventLocation:'',
            eventAddress: '',
            eventCity: '',
            eventZipCode:'',
            eventState: '',
            showInfoModal: false

        }
    }

    async componentDidMount() {
        let errorMessage = '';

        if(this.props.location.state){
            errorMessage = this.props.location.state.hasOwnProperty("errorMessage") ? this.props.location.state.errorMessage : ''
        }
        this.setState(function(){
            return { errorMessage: errorMessage}
        });
        

        RequisitionService.getRequisitionNumberByApplId(window.sessionStorage.getItem('applicantID')).then(this.getRequisitionNumberByApplIdOnComplete);

        const calResponse = await RequisitionService.getCalendarDetails().then(resp => {
            return resp
        });
        this.setState(function () {
            return {
                showInfoModal: calResponse.data.reqCalendarTypeCd === '20',
                eventLocation: calResponse.data.eventLocation ? calResponse.data.eventLocation  : '',
                eventAddress: calResponse.data.eventAddress? calResponse.data.eventAddress  : '',
                eventCity: calResponse.data.eventCity? calResponse.data.eventCity  : '',
                eventZipCode: calResponse.data.eventZipCode? calResponse.data.eventZipCode  : '',
                eventState: calResponse.data.eventState? calResponse.data.eventState  : ''
            }
        });
    }

    handleCloseInfoModal = () => {
        this.setState({showInfoModal: false});
    }

    getRequisitionNumberByApplIdOnComplete = (resp) => {
        let reqNbr;
        if(resp.data.requisitionNumber){
            reqNbr = resp.data.requisitionNumber;
            SchedulingService.getAvailableTimeslots(reqNbr, 7).then(this.getAvailableTimeSlotsOnComplete.bind(null, reqNbr));
            this.setState(function(){
                return {
                    reqnId: reqNbr
                }
            });
        } else {
            this.setState(function() {
                return {
                    errorMessage: "Error retrieving Calendar: Requisition Not Found"
                }
            })
        }
    }

    getAvailableTimeSlotsOnComplete = (reqNbr, resp) => {
        if(resp.data.availableSlots){
            this.setState(function(){
                return {
                    availableSlots: resp.data.availableSlots,
                    loaded:true
                }
            });
        } else {
            if(this.state.reschedule) {
                this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=14"});
            }else{
                if(this.state.offer) {
                    CandidateStatusService.updateTacOfferStatus(reqNbr, 2).then(this.updateTacNoSlotsOnComplete)
                }else {
                    PhoneScreenService.updateInterviewStatus(window.sessionStorage.getItem('applicantID'), reqNbr, 15).then(this.updateInterviewStatusOnComplete);
                }
            }
        }
    }

    updateInterviewStatusOnComplete = () => {
        this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=14"});
    }

    updateTacNoSlotsOnComplete = () => {
        this.props.history.push({pathname:PageEnum.Message.url, search:"?msgId=25"})
    }

    closeEventHandler = () => {
        this.setState(function() {
            return {errorMessage: ''}
        });
    }

    clickEventHandler = (dayText, time, storeNumber, index1, index2) => {
        this.setState(function() {
            return {selected1: index1,
                    selected2: index2,
                    storeNumber: storeNumber,
                    dayText: dayText,
                    time:time}
        });
    }

    moreEventHandler = () => {
        this.setState(function() {
            return {showExtra: !this.state.showExtra }
        });
    }

    selectTimeHandler = () => {
        let applicantId = window.sessionStorage.getItem('applicantID');
        if (this.state.offer) {
            this.setLoaded(false);
            if (this.state.reschedule) {
                RequisitionService.getPositionDetails(applicantId).then(this.getPositionDetailsOnComplete.bind(null, this.state.dayText, this.state.time));
            } else {
                SchedulingService.reserveTimeSlotAndUpdateOfferStatus(this.state.reqnId, applicantId, this.state.time).then(this.reserveTimeSlotAndUpdateOfferStatusOnComplete).catch(() => {
                    this.props.history.push({pathname: PageEnum.Calendar.url, search: "?reqnId=" + this.state.reqnId+"&offer=true", state: { errorMessage: "Error processing data.  Please try again." }});
                    window.location.reload(true);
                });
            }
        } else {
            this.getStoreDetailsAndRoute(this.state.reqnId, this.state.dayText, this.state.time, this.state.storeNumber);
        }
    }

    setLoaded (isLoaded){
        this.setState(function () {
            return {loaded : isLoaded}
        });
    }

    getStoreDetailsAndRoute = (reqnNbr, dayText, time, storeNumber) => {
        RequisitionService.getHiringEventDetails(reqnNbr).then(this.getHiringEventDetailsOnComplete.bind(null, dayText, time, storeNumber));
    }

    getPositionDetailsOnComplete = (dayText, time, resp) => {
        this.setLoaded(true);
        if(resp && resp.data){
            this.props.history.push({pathname: PageEnum.OfferConfirmation.url, state: {locationLine1: resp.data.addressLine1, locationLine2: resp.data.addressLine2,
                    dayText: dayText, time: Time.getTimeFromDate(time), timeSlot: time, reqnNbr: this.state.reqnId, reschedule: this.state.reschedule, jobDescription:resp.data.jobDescription,
                    employmentType:resp.data.employmentType, days: resp.data.days, times: resp.data.times, pay: resp.data.pay}});
        } else {
        }
    }

    getHiringEventDetailsOnComplete = ( dayText, time, storeNumber, resp) => {
        if(resp && resp.data){
            let locationLine1 = resp.data.eventAddress;
            let locationLine2 = resp.data.eventCity + ", " + resp.data.eventStateCd + " " + resp.data.eventZipcode;
            JobDetailsService.getJobTitleDescription(this.state.reqnId).then(this.jobTitleDescriptionOnComplete.bind(null, dayText, time, locationLine1, locationLine2, resp.data.phoneNumber));
        } else {
            StoreDetailsService.getStoreDeatilsServiceByStoreDetails(storeNumber).then(this.getStoreDetailsOnComplete.bind(null, dayText, time));
        }
    }

    reserveTimeSlotAndUpdateOfferStatusOnComplete = (resp) => {
        if (resp && resp.data) {
            this.setLoaded(true);
            if (resp.data.message) {
                this.props.history.push({pathname: PageEnum.Calendar.url, search: "?reqnId=" + this.state.reqnId+"&offer=true", state: { errorMessage: resp.data.message }});

            } else if (resp.data.success) {
                this.props.history.push({pathname: PageEnum.OfferConfirmed.url, search: ""});
            }
        } else {
            this.props.history.push({pathname: PageEnum.Calendar.url, search: "?reqnId=" + this.state.reqnId+"&offer=true", state: { errorMessage: "Error processing data.  Please try again." }});
            window.location.reload();
        }
    }


    getStoreDetailsOnComplete = (dayText, time, resp) => {
        if(resp && resp.data){
            let locationLine1 = resp.data.addressLine1 + (resp.data.addressLine2 ? " " + resp.data.addressLine2 + "," : ",");
            let locationLine2 = resp.data.cityName + ", " + resp.data.stateCode + " " + resp.data.zipcode;
            JobDetailsService.getJobTitleDescription(this.state.reqnId).then(this.jobTitleDescriptionOnComplete.bind(null, dayText, time, locationLine1, locationLine2, resp.data.phoneNumber));
        } else {
            //TODO: need to display a error i guess?
        }
    }
    jobTitleDescriptionOnComplete = (dayText, time,locationLine1, locationLine2, phoneNumber, response) => {
        if(response && response.data && response.data.jobTitleDescription){
            this.setState(function () {
                return {
                    position: response.data.jobTitleDescription
                }
            });
            this.props.history.push({pathname: PageEnum.CalendarConfirmation.url, state: {locationLine1: locationLine1, locationLine2: locationLine2, dayText: dayText, time: Time.getTimeFromDate(time), timeSlot: time, reqnNbr: this.state.reqnId, reschedule: this.state.reschedule, position:this.state.position, phoneNumber:phoneNumber }});

        } else {
        }
    }

    backHandler = () => {
        var applicantId = window.sessionStorage.getItem('applicantID');
        UpdateAndRoutingService.updateAndGetRouteAuthentication().then(this.updateAndGetRouteOnComplete);

    }

    updateAndGetRouteOnComplete = (response) => {
        if(response.data.msgId){
            this.props.history.push({pathname: PageEnum[(response.data.pages)].url, search: "?msgId=" + response.data.msgId});
        } else {
            this.props.history.push({pathname: PageEnum[(response.data.pages)].url});
        }
    }

    render() {
        let {reschedule, offer, eventLocation, eventAddress, eventCity, eventZipCode, eventState, showInfoModal} = this.state;

        return (
            <div>
                {this.state.loaded ? (
                    <div>
                        {(!offer && !reschedule)  && <InfoModal show={showInfoModal} eventLocation={eventLocation} eventAddress={eventAddress}
                                   eventCity={eventCity} eventZipCode={eventZipCode}
                                   eventState={eventState} handleClose={this.handleCloseInfoModal}/>}
                        <ErrorBanner submitErrorMessage={this.state.errorMessage} closeEventHandler={this.closeEventHandler} />
                        <Row>
                            <Col className='col-12'>
                                { (this.state.reschedule === false && this.state.offer === false) &&
                                <div>
                                    <h2>Great News!</h2>
                                    <p>We would like to schedule you for a face-to-face interview. Please select an interview time below.</p>
                                </div>}
                                { this.state.reschedule === true &&
                                <div>
                                    <h2>Reschedule</h2>
                                    <p>To reschedule{!this.state.offer &&<span> your interview</span>}, please select one of the options below.<br/>
                                       Please note: you are only able to reschedule one time for this position</p>
                                </div>}
                                { ((this.state.offer === true)&&(!this.state.reschedule)) &&
                                <div>
                                    <h2>Schedule your visit</h2>
                                    <p>You're almost done! Select from the available time below, click 'Select Time' at the
                                     bottom of this page to confirm.</p>
                                </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                    {(this.state.availableSlots) ? <CalendarDaySlots availableSlots={this.state.availableSlots} clickEvent={this.clickEventHandler} selected1={this.state.selected1} selected2={this.state.selected2} showExtra={this.state.showExtra}/> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm ={12}><div className="moreButton" align="center" onClick={this.moreEventHandler}>{(this.state.showExtra && (this.state.availableSlots.length>3) ) ? <span>Collapse only see first 3 days</span>:<span>Need another day?</span>}</div></Col>
                            {this.state.showExtra && <Col sm = {12}><div className="extraVerbiage">{this.state.availableSlots.length>3? <span>These are the only available times for the next 7 days. Please select a day and time above.</span>:<span>The location does not have any additional times within the next seven days. Please select a day and time above.</span>}</div></Col>}
                        </Row>
                <Row className='topMargin '>
                    <Col sm={12} md={7}  lg={7} xl={7} className="largeVerticalMargins selectTimeBtn col-12">
                        <button  className="button submitbutton bsizeBig primary" disabled={this.state.selected1<0} type="button" onClick={this.selectTimeHandler}>
                            Select Time
                        </button>
                    </Col>

                {this.state.reschedule === true &&

                    <Col xs={12} sm={12} md={4} lg={4} className='buttonRightAlign'>
                        <button id="backBtn" className="button submitbutton bsize " type="button" onClick={this.backHandler}>
                            Back
                        </button>
                    </Col>
                }
                </Row>
               </div>
                ) : <div className="loader">Loading...</div> }
            </div>
        )
    }
}

export default Calendar;