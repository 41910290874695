import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

function PositionDetails(props) {
    return (
        <div className="confirmationForm">
            {props.dayText && <Row>
                <Col className='col-3'>
                    Date:
                </Col>
                <Col className='col-9'>
                    {props.dayText}
                </Col>
            </Row>}
            {props.time && <Row>
                <Col className='col-3'>
                    Time:
                </Col>
                <Col className='col-9'>
                    {props.time}
                </Col>
            </Row>}
            <Row>
                <Col className='col-3'>
                    Position:
                </Col>
                <Col className='col-9'>
                    {props.employmentType} {props.jobDescription}
                </Col>
            </Row>
            <Row>
                <Col className='col-3'>
                    Work location:
                </Col>
                <Col className='col-9'>
                    {props.addressLine1}<br/>
                    {props.addressLine2}
                </Col>
            </Row>
            {props.eventAddress3 && <Row>
                <Col className='col-3'>
                    Appointment location:
                </Col>
                <Col className='col-9'>
                    {props.eventAddress1}<br/>
                    {props.eventAddress2}<br/>
                    {props.eventAddress3}
                </Col>
            </Row>}
            <Row>
                <Col className='col-3'>
                    Initial work schedule:
                </Col>
                 <Col className='col-9'>
                    {props.days}
                     {props.times && <span><br/> {props.times}</span>}
                </Col>
            </Row>
            <Row>
                <Col className='col-3'>
                    Starting wage:
                </Col>
                <Col className='col-9'>
                    {props.pay}/hour
                </Col>
            </Row>
        </div>
    )
}

PositionDetails.propTypes = {
    jobDescription: PropTypes.string.isRequired,
    employmentType: PropTypes.string,
    pay: PropTypes.string,
    schedule: PropTypes.string,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    dayText: PropTypes.string,
    time: PropTypes.string
};

export default PositionDetails;