import {remove} from "react-cookie";
import DeAuthenticationService from "./DeAuthenticationService";


const RedirectService = {


     redirect : (error) => {
         const {response} = error;
        if(response != null && response.status === 401 && response.data != null && response.data.redirect != null){

            window.sessionStorage.removeItem('CSSAuthenticated');
            window.sessionStorage.removeItem('applicantID');
            DeAuthenticationService.logout().then();
            window.open("/authenticate?error=session", "_self");
        }

    }

}

export default RedirectService;