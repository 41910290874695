import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

function InterviewDetails(props) {
    return (
        <div className="confirmationForm">
            <Row>
                <Col className={"col-3"}>
                    Date:
                </Col>
                <Col className={"col-9"}>
                    {props.dayText}
                </Col>
            </Row>
            <Row>
                <Col className={"col-3"}>
                    Time:
                </Col>
                <Col className={"col-9"}>
                    {props.time}
                </Col>
            </Row>
            <Row>
                <Col className={"col-3"}>
                    Position:
                </Col>
                <Col className={"col-9"}>
                    {props.position}
                </Col>
            </Row>
            <Row>
                <Col className={"col-3"}>
                    Work location:
                </Col>
                <Col className={"col-9"}>
                    {props.locationLine1}<br />
                    {props.locationLine2}
                </Col>
            </Row>
                { props.showNumber &&
                          <Row >
                            <Col className={"col-3"}>
                                Phone:
                            </Col>
                            <Col className={"col-9"}>
                                {props.phoneNumber}
                            </Col>
                        </Row>
                    }
        </div>
    )
}

InterviewDetails.propTypes = {
    locationLine1: PropTypes.string.isRequired,
    locationLine2: PropTypes.string,
    phoneNumber: PropTypes.string,
    position: PropTypes.string,
    time: PropTypes.string.isRequired,
    dayText: PropTypes.string.isRequired,
    showNumber:PropTypes.bool
};

export default InterviewDetails;