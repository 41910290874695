import axios from 'axios';
import ApiUrls from '../Util/ApiUrls';

var config ={
    // headers: {'api_key': '08a0a442-c7a6-443a-8a66-c597d9348a56'}
};

var ApplicantAuthService = {
  getApplicantInfoIfActive: function(candidateId){
    return axios.get('/auth/applicantAuth/' + candidateId,config.headers).then(function(response){
      return response;
    });
  },
  authenticateWithLastnameCandIdAndZipcode: function(candId, lastName, zipCode){

    return  axios.get('/auth/applicantAuth/' + candId + '/' + lastName.replace(/'/g, "%27") + '/' + zipCode, config.headers).then(function(response){
      return response;
    })
  }
}
export default ApplicantAuthService;