import axios from 'axios';
import redirectService from "./RedirectService";


var CandidateStatusService = {
    updatePhoneScreenStatus:function(statusCode){
        return axios.put('/api/phoneScreen/status/' + statusCode, '', {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    updateTacOfferStatus:function(requisitionNumber, offerStatusCode) {
        return axios.put('/api/phoneScreen/offerStatus/' + requisitionNumber + '/' + offerStatusCode, '', {
            headers: {
                "Content-type": "application/json"
            }
        }).then(function (response) {
            return response;
        }).catch(e => redirectService.redirect(e));
    }
}

export default CandidateStatusService;