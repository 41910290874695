import React from 'react';
import { Row, Col } from 'react-bootstrap';
import QuestionService from '../Services/QuestionService';
import titleCase from 'title-case';
import UpdateAndRoutingService from '../Services/UpdateAndRoutingService';
import ErrorBanner from '../components/ErrorBanner';
import PageEnum from '../Util/PageEnum';
import PhoneScreenService from '../Services/PhoneScreenService';
import RequisitionService from '../Services/RequisitionService';
import Loader from '../components/Loader';


class Questions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            applicantId: window.sessionStorage.getItem('applicantID'),
            questionsList: [{ "text": '', "phoneScreenQuestionCode": '' }],
            errorMessage: '',
            qIndex: 0,
            schedulingQuestions: ["14", "15", "16", "17", "18", "19", "20", "21", "22"],
            accomodation: 23,
            showAccomodation: false,
            applId: '',
            loaded: true,
            reqnId: '',
            scheduleMatchIndicator: ''
        }

        this.noEventHandler = this.noEventHandler.bind(this);
    }
    
    disableLoadingIcon = (response) => {
        this.setState(function () {
            return {
                loaded: response
            }
        });
    } 

    UNSAFE_componentWillMount() {
        var applId = window.sessionStorage.getItem('applicantID');
        this.disableLoadingIcon(false);
        this.setState(function () {
            return {
                applId: applId
            }
        });

        RequisitionService.getRequisitionDetailsByApplId(applId).then(this.requisitionDetailsOnComplete);
        QuestionService.getQuestions(applId).then(this.getQuestionsResponse);
    }

    getQuestionsResponse = (response) => {
        if (response && response.data) {
            var questions = response.data.questionResp;
            this.disableLoadingIcon(true);
            this.setState(function () {
                return {
                    questionsList: questions
                }
            });
        }
    }

    requisitionDetailsOnComplete = (response) => {
        if (response && response.data) {
            let scheduleMatch = response.data.scheduleIndicator;
            this.setState(function() {
                return {
                    scheduleMatchIndicator:scheduleMatch
                }
            });
        }
    }

    noEventHandler = () => {
        this.setState({
            loaded: !this.state.loaded
        });
        if(this.isSchedulingQuestion()) {       
            this.noEventHandler_SchedulingQuestion();
        } else {           
            this.noEventHandler_NonSchedulingQuestion();
        }

    }

    isSchedulingQuestion = () => {
        if(this.state.schedulingQuestions.indexOf(this.state.questionsList[this.state.qIndex].phoneScreenQuestionCode) != -1){
            return true;
        } else {
            return false;
        }
    }

    noEventHandler_SchedulingQuestion = () => {
        QuestionService.updatePhoneScreenQuestionFlag(this.state.applicantId, this.state.questionsList[this.state.qIndex].phoneScreenQuestionCode, "N").then(this.updatePhoneScreenQuestionFlag_AccomodationOnComplete).catch(() => {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' 
                }
            });
        });
        
        var newIndex =  this.state.qIndex + 1
        this.setState(function () {
            return { 
                showAccomodation: true,
                qIndex: newIndex
            }
        });
    }

    updatePhoneScreenQuestionFlag_AccomodationOnComplete = (resp) => {       
        if (resp && resp.data && resp.data.rowsUpdated !== 1) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return {                    
                    errorMessage: 'Error processing data.  Please try again.' 
                }
            });
        }
        this.disableLoadingIcon(true);  
    }

    noEventHandler_NonSchedulingQuestion = () => {
        PhoneScreenService.updateMinReqAndPhoneScreenStatus(this.state.applicantId, 5, 5).then(this.updateMinReqAndPhoneScreenStatusOnComplete);
        RequisitionService.getRequisitionNumberByApplId(this.state.applicantId).then(this.getRequisitionNumberByApplIdOnComplete);
        QuestionService.updatePhoneScreenQuestionFlag(this.state.applicantId, this.state.questionsList[this.state.qIndex].phoneScreenQuestionCode, "N").then(this.updatePhoneScreenQuestionFlag_NoAccomodationOnComplete).catch(() => {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        });
    }

    updatePhoneScreenQuestionFlag_NoAccomodationOnComplete = (resp) => {
        this.disableLoadingIcon(true);
        if (resp && resp.data && resp.data.rowsUpdated === 1) {
            //Changes for Job Recommendation Was route 13 now route 26 (Same page different msg)
            // this.props.history.push({ pathname: PageEnum.Message.url, search: "?msgId=13" });
            this.props.history.push({ pathname: PageEnum.Message.url, search: "?msgId=26" });
        } else {
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    updateMinReqAndPhoneScreenStatusOnComplete = (resp) => {
        if (!resp || !resp.data) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    updateInterviewStatusOnComplete = (resp) => {
        if (!resp || !resp.data || resp.data.rowsUpdated !== 1) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    getRequisitionNumberByApplIdOnComplete = (resp) => {
        if (!resp || !resp.data) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
        else{
            var reqNbr = resp.data.requisitionNumber;
            PhoneScreenService.updateInterviewStatus(this.state.applicantId,reqNbr,0).then(this.updateInterviewStatusOnComplete);
            this.setState(function () {
                return {
                    reqnId: reqNbr
                }
            });
        }
    }
    
    yesEventHandler = () => {
        this.setState({
            loaded: !this.state.loaded
        });
        QuestionService.updatePhoneScreenQuestionFlag(window.sessionStorage.getItem('applicantID'), this.state.questionsList[this.state.qIndex].phoneScreenQuestionCode, "Y").then(this.updatePhoneScreenQuestionFlagOnComplete).catch(() => {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        });
    }

    updatePhoneScreenQuestionFlagOnComplete = (resp) => {
        var newIndex;
        if (resp && resp.data && resp.data.rowsUpdated === 1) {
            newIndex = this.getNewQuestionIndex();
            if (this.isOutOfBounds(newIndex)) {

                this.googleAnalyticsFire();
                switch(this.state.scheduleMatchIndicator){
                    case "P":
                    case "M":
                        this.props.history.push({pathname:PageEnum.Schedule.url});
                        break;
                    default:
                        UpdateAndRoutingService.updateAndRouteQuestions(this.state.applId).then(this.updateAndRouteQuestionsOnComplete);
                        break;
                }
            } else {
                this.disableLoadingIcon(true);
                this.setState(function () {
                    return {
                        qIndex: newIndex,
                        errorMessage: ''
                    }
                });
            }
        } else {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    getNewQuestionIndex = () => {
        this.disableLoadingIcon(false);
        var newIndex = this.state.qIndex + 1;
        if(this.state.questionsList[newIndex] && this.state.questionsList[newIndex].phoneScreenQuestionCode == this.state.accomodation){
            if(!this.state.showAccomodation){
                    newIndex += 1; // index got incremented by 2 overall
            } 
        } 
        return newIndex;
    }

    updateAndRouteQuestionsOnComplete = (response) => {
        this.disableLoadingIcon(true);
        if (response.data.msgId) {
            this.props.history.push({ pathname: PageEnum[titleCase(response.data.pages)].url, search: "?msgId=" + response.data.msgId });
        } else {
            this.props.history.push({ pathname: PageEnum[titleCase(response.data.pages)].url });
        }
    }

    isOutOfBounds = (index) => {
        if(index < this.state.questionsList.length){
            return false;
        } else {
            return true;
        }
    }

    googleAnalyticsFire = () => {
        window.dataLayer.push({
            'event' : 'last_question_event'
        });
    }

    questionsJSX = () => {
        return(
            <div className="questions">
                <ErrorBanner submitErrorMessage={this.state.errorMessage} closeEventHandler={this.closeEventHandler} />
                <Row>
                    <Col sm={12} className='getQuestions'>
                        <span className="msg_txt" dangerouslySetInnerHTML={{ __html: this.state.questionsList[this.state.qIndex].text }} />
                    </Col>

                </Row>
                <Row>
                    <Col sm={12} md={4}>
                        <button className="button yesno" type="button" onClick={this.yesEventHandler}> Yes </button>
                        <button className="button yesno" type="button" onClick={this.noEventHandler}> No </button>
                    </Col>
                </Row>
            </div>
        );
    }


    render() {

        let renderJSX = null;

        if (this.state.loaded)
            renderJSX = this.questionsJSX();
        else
            renderJSX = <Loader/>;
            
        return (
            <div>
                {renderJSX}
            </div>
        );
    }
}

export default Questions;
