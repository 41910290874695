import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Time from '../Util/Time';

function CalendarDaySlots(props){


    return (
        <div key={props.availableSlots.length+"CalendarDaySlots"}>
            {props.availableSlots ? props.availableSlots.map(function(slots, index){
                return (<div key={index + "extraSlotWrapperCheck"}>

                    {(props.showExtra ||(index < 3)) && <div key={index + "CalendarDaySlotsWrapper"} className="CalendarDaySlotsWrapper" >
                        <Row key={index + "CalendarDaySlotHeading"} bsClass='row CalendarDaySlotHeading'>
                            <Col key={index + "ColHeading"} className={"col-12"} >
                            <h3 key={index + "Text"}>{ slots.text.toUpperCase() }</h3>
                        </Col>
                        </Row>
                        <Row key={index + "CalendarDaySlot"} bsClass='row CalendarDaySlot'>
                            {slots.slots.map(function(slot, index2){
                                return (
                                    <Col key={index2 + "ColSlot"} className='col-6' sm={6} md={2} >
                                        <div key={index2 + "DivSlot"} className={(((props.selected1 == index)&&(props.selected2==index2))? 'selectedSlot':'timeSlot') } onClick={props.clickEvent.bind(null, slots.text, slot.timeSlot, slot.storeNumber, index, index2)}>
                                            {Time.getTimeFromDate(slot.timeSlot)}
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>}
                </div>

                )
            }): ""}
        </div>
    )
}

CalendarDaySlots.defaultProps = {
    submitErrorMessage: ''
}

CalendarDaySlots.propTypes = {
    availableSlots: PropTypes.arrayOf(PropTypes.shape({
        slots: PropTypes.arrayOf(PropTypes.shape({
            timeSlot:PropTypes.string.isRequired,
            timeZone: PropTypes.string.isRequired
        })),
        text: PropTypes.string.isRequired
    })).isRequired,
    selected1: PropTypes.number,
    selected2: PropTypes.number,
    clickEvent: PropTypes.func.isRequired,
    showExtra: PropTypes.bool
};

export default CalendarDaySlots;
