import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Time from '../Util/Time';


function DaySchedules(props){

    return (
        <div>

                {props.days ? props.days.map(function(slots, index){
                    return(
                        <div key={index + "Day"} className='scheduleSlot'>
                            <Row  className={`shiftTitle  ${(slots.beginTime && slots.endTime) ? "activeSlot" : ""}`}>
                                <Col  className="col-4" >{slots.dayName+':'}</Col>
                                {(slots.beginTime && slots.endTime) ? <Col className="col-8 text-right"  ><div>{Time.tConvert(slots.beginTime)} - {Time.tConvert(slots.endTime)}</div>
                                        {'Y'== slots.overNight ? <Col  className="overNight pull-right">Overnight</Col> :""}
                                    </Col>


                                    : <Col className='col-8 text-center'>No shift</Col>}
                            </Row>
                            {(slots.beginTime && slots.endTime) ? <Row className="shiftSelector">
                                <Col className={`col-6 shiftAnswer ${(slots.answer=='Y')? "shiftSelected":""} `} onClick={props.clickEvent.bind(null, index, 'Y')} >Yes</Col>
                                <Col className={`col-6 shiftAnswer ${(slots.answer=='N')? "shiftSelected":""} `} onClick={props.clickEvent.bind(null, index, 'N')} ><div hidden={slots.answer} className="shiftBorder"></div>No</Col></Row>:""}
                        </div>)
                }):""}


        </div>
    )}


DaySchedules.propTypes = {
    days: PropTypes.arrayOf(PropTypes.shape({
            dayName:PropTypes.string.isRequired,
            beginTime:PropTypes.string,
            endTime:PropTypes.string,
            answer:PropTypes.string,
            overNight:PropTypes.string
    })).isRequired,
    clickEvent: PropTypes.func.isRequired
};

export default DaySchedules;