import React from 'react';
import { Row, Col } from 'react-bootstrap';
import InterviewDetails from './InterviewDetails';
import PageEnum from "../Util/PageEnum";
import queryString from "query-string";
import {createBrowserHistory} from 'history';
import DeAuthenticationService from "../Services/DeAuthenticationService";
import RequisitionService from "../Services/RequisitionService";
import Time from "../Util/Time";
import Loader from "./Loader";
import InfoModal from "./InfoModal";


class CalendarConfirmed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dayText: '',
            time: '',
            locationLine1: '',
            locationLine2: '',
            phoneNumber: '',
            position: '',
            timeSlot: '',
            reqnNbr: '',
            rescheduled: false,
            availability: true,
            timeLeftToReschedule: true,
            earlyClose: false,
            locationType: '',
            showInfoModal: false,
            isLoaded: false,
            eventLocation: '',
            eventAddress: '',
            eventCity: '',
            eventZipCode: '',
            eventState: ''
        }
    }

    async UNSAFE_componentWillMount() {
        console.log("UNSAFE_componentWillMount: "+this.props);
        await this.updateStateWithProps(this.props);
    }

    async UNSAFE_componentWillReceiveProps(nextProps){
        console.log("UNSAFE_componentWillReceiveProps: "+ nextProps);
        await this.updateStateWithProps(nextProps);
    }

    async componentDidMount() {
        const calResponse = await RequisitionService.getCalendarDetails().then(resp => {
            return resp
        });
        this.setState(function () {
            return {
                showInfoModal: calResponse.data.reqCalendarTypeCd === '20',
                eventLocation: calResponse.data.eventLocation ? calResponse.data.eventLocation  : '',
                eventAddress: calResponse.data.eventAddress? calResponse.data.eventAddress  : '',
                eventCity: calResponse.data.eventCity? calResponse.data.eventCity  : '',
                eventZipCode: calResponse.data.eventZipCode? calResponse.data.eventZipCode  : '',
                eventState: calResponse.data.eventState? calResponse.data.eventState  : '',
                isLoaded: true
            }
        });
    }

    deAuthentication = () => {
        window.sessionStorage.removeItem('CSSAuthenticated');
        window.sessionStorage.removeItem('applicantID');
        DeAuthenticationService.logout().then();
        this.props.history.push({ pathname: PageEnum.Authentication });
    }

    updateStateWithProps = async (theProps) => {
        const history = createBrowserHistory();
        console.log("updateStateWithProps The Props:" +theProps);

        if (theProps.hasOwnProperty("location")) {
            history.push({pathname: PageEnum.Authentication.url});
            // const calResponse = await RequisitionService.getCalendarDetails(theProps.location.state.reqnNbr).then(resp => {
            //     return resp
            // });
            this.setState(function () {
                return {
                    dayText: theProps.location.state.hasOwnProperty("dayText") ? theProps.location.state.dayText : '',
                    time: theProps.location.state.hasOwnProperty("time") ? theProps.location.state.time : '',
                    locationLine1: theProps.location.state.hasOwnProperty("locationLine1") ? theProps.location.state.locationLine1 : '',
                    locationLine2: theProps.location.state.hasOwnProperty("locationLine2") ? theProps.location.state.locationLine2 : '',
                    phoneNumber: theProps.location.state.hasOwnProperty("phoneNumber") ? theProps.location.state.phoneNumber : '',
                    position: theProps.location.state.hasOwnProperty("position") ? theProps.location.state.position : '',
                    timeSlot: theProps.location.state.hasOwnProperty("timeSlot") ? theProps.location.state.timeSlot : '',
                    reqnNbr: theProps.location.state.hasOwnProperty("reqnNbr") ? theProps.location.state.reqnNbr : '',
                    rescheduled: theProps.location.state.hasOwnProperty("rescheduled") ? theProps.location.state.rescheduled : false,
                    availability: theProps.location.state.hasOwnProperty("availability") ? theProps.location.state.availability : true,
                    timeLeftToReschedule: theProps.location.state.hasOwnProperty("timeLeftToReschedule") ? theProps.location.state.timeLeftToReschedule : true,
                    earlyClose: theProps.location.state.hasOwnProperty("earlyClose") ? theProps.location.state.earlyClose : false,
                    locationType: theProps.location.state.hasOwnProperty("locationType") ? theProps.location.state.locationType : '',
                    // eventLocation: calResponse.data.eventLocation ? calResponse.data.eventLocation  : '',
                    // eventAddress: calResponse.data.eventAddress? calResponse.data.eventAddress  : '',
                    // eventCity: calResponse.data.eventCity? calResponse.data.eventCity  : '',
                    // eventZipCode: calResponse.data.eventZipCode? calResponse.data.eventZipCode  : '',
                    // eventState: calResponse.data.eventState? calResponse.data.eventState  : '',
                    // showInfoModal: calResponse.data.reqCalendarTypeCd === '20',
                    isLoaded:true


                }
            });
        } else if (theProps.hasOwnProperty("dayText")) {
            history.push({pathname: PageEnum.Authentication.url});
            // const calResponse = await RequisitionService.getCalendarDetails(theProps.reqnNbr).then(resp => {
            //     return resp
            // });
            this.setState(function () {
                return {
                    dayText: theProps.hasOwnProperty("dayText") ? theProps.dayText : '',
                    time: theProps.hasOwnProperty("time") ? theProps.time : '',
                    locationLine1: theProps.hasOwnProperty("locationLine1") ? theProps.locationLine1 : '',
                    locationLine2: theProps.hasOwnProperty("locationLine2") ? theProps.locationLine2 : '',
                    phoneNumber: theProps.hasOwnProperty("phoneNumber") ? theProps.phoneNumber : '',
                    position: theProps.hasOwnProperty("position") ? theProps.position : '',
                    timeSlot: theProps.hasOwnProperty("timeSlot") ? theProps.timeSlot : '',
                    reqnNbr: theProps.hasOwnProperty("reqnNbr") ? theProps.reqnNbr : '',
                    rescheduled: theProps.hasOwnProperty("rescheduled") ? theProps.rescheduled : false,
                    availability: theProps.hasOwnProperty("availability") ? theProps.availability : true,
                    timeLeftToReschedule: theProps.hasOwnProperty("timeLeftToReschedule") ? theProps.timeLeftToReschedule : true,
                    earlyClose: theProps.hasOwnProperty("earlyClose") ? theProps.earlyClose : true,
                    locationType: theProps.hasOwnProperty("locationType") ? theProps.locationType : '',
                    // eventLocation: calResponse.data.eventLocation ? calResponse.data.eventLocation  : '',
                    // eventAddress: calResponse.data.eventAddress? calResponse.data.eventAddress  : '',
                    // eventCity: calResponse.data.eventCity? calResponse.data.eventCity  : '',
                    // eventZipCode: calResponse.data.eventZipCode? calResponse.data.eventZipCode  : '',
                    // eventState: calResponse.data.eventState? calResponse.data.eventState  : '',
                    // showInfoModal: calResponse.data.reqCalendarTypeCd === '20',
                    isLoaded:true
                }
            });
        }
    }

    handleCloseInfoModal = () => {
        this.setState({showInfoModal: false});
    }

    logoutHandler = () => {

        this.deAuthentication();
        this.props.history.push({pathname:PageEnum.Authentication.url});
    }

    rescheduleHandler = () => {
        this.props.history.push({ pathname: PageEnum.Calendar.url, search: '?reschedule=true' });
    }
    
    cancelHandler = () => {
        this.props.history.push({pathname: PageEnum.CalendarConfirmation.url, state: {locationLine1: this.state.locationLine1, locationLine2: this.state.locationLine2, dayText: this.state.dayText, time: this.state.time, timeSlot: this.state.timeSlot, reqnNbr: this.state.reqnNbr, reschedule: this.state.reschedule, position:this.state.position, phoneNumber:this.state.phoneNumber, cancel:true }});
    }

    render () {
        let {isLoaded, showInfoModal, rescheduled, availability,timeLeftToReschedule,earlyClose,
            eventLocation, eventAddress, eventCity, eventZipCode, eventState} = this.state;
        let isDC = this.state.locationType === 'DC';


        return (
            <div>
                {!isLoaded && <Loader/>}
                {isLoaded && <InfoModal show={showInfoModal} eventLocation={eventLocation} eventAddress={eventAddress}
                                        eventCity={eventCity} eventZipCode={eventZipCode}
                                        eventState={eventState} handleClose={this.handleCloseInfoModal}/>}
                {isLoaded && <div id='header'>
                    <Row>
                        <Col className={"col-12"}>
                            <h2>Interview {rescheduled ? 'Rescheduled' : 'Details'}</h2>
                            <p className="noBottomMargin"> {rescheduled ? 'Your interview has been rescheduled.' : 'You are scheduled for an interview.'} </p>
                        </Col>
                    </Row>
                    {<InterviewDetails dayText={this.state.dayText} time={this.state.time} locationLine1={this.state.locationLine1} locationLine2={this.state.locationLine2} phoneNumber = {this.state.phoneNumber} showNumber ={true} position ={this.state.position} />}
                    {/*COVID VERBIAGE START*/}
                    {earlyClose && <Row>
                        <Col className={"col-12"}>
                            Due to recent events, the location will be closed at 8:00 pm. Your interview will be conducted over the phone at the time listed above.<br/><br/>
                        </Col>
                    </Row>}
                    {/*COVID VERBIAGE END*/}
                    <Row>
                        <Col className={"col-12"}>
                            Additional interview details have been sent to your email address. Please check your email for
                            additional information related to your visit.
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"col-12"}>
                            <br/>
                            {(!rescheduled && timeLeftToReschedule) && 'If you need to reschedule or cancel your interview, select the buttons below. If you are unable to ' +
                                'attend your scheduled time, you have one-time opportunity to reschedule your interview. '}
                            By selecting 'Cancel Interview', you will no longer be considered for this position.
                            <br/><br/>
                            <p>See you soon!</p>
                        </Col>
                    </Row>
                    {(!rescheduled && !availability && timeLeftToReschedule)&& <Row>
                        <Col className={"col-12"}>
                            <br/><b>Please Note:</b> Currently, this location does not have any timeslots available for rescheduling an interview. Check back at a later time to see if timeslots have been added. <br/><br/>  You have up until 2 hours before your initial interview to reschedule your interview through Candidate Self Service.
                        </Col>
                    </Row>}
                    {(!timeLeftToReschedule) && <Row>
                        <Col className={"col-12"}>
                            <br/><b>Please Note:</b> We are unable to {!rescheduled && <span>reschedule or </span>} cancel your interview since it is 2 hours or less until your scheduled time. Contact the location at the number listed above to {!rescheduled && <span>reschedule or </span>} cancel your interview.<br/><br/>
                        </Col>
                    </Row>}
                    {/* {modalContent} */}
                    <div>
                        <Row className="bigScreenVerticalMargins pushLeft">
                            <Col sm={12} md={4} className="largeVerticalMargins">
                                <button id="butConfirmation" className="button primary submitbutton bsize" type="button" onClick={this.logoutHandler}>Log-out</button>
                            </Col>
                            <Col sm={12} md={4}>
                                {(!rescheduled && timeLeftToReschedule) && <div>
                                <button disabled={!availability || !timeLeftToReschedule} className="button submitbutton bsize" type="button" id="rescheduleBtn" onClick={this.rescheduleHandler}>Reschedule Interview</button>
                            </div>}</Col>
                            <Col sm={12} md={4} className="largeVerticalMargins">
                                {timeLeftToReschedule &&
                                <button disabled={!timeLeftToReschedule} className="button submitbutton bsize" type="button" id="cancelBtn" onClick={this.cancelHandler}>Cancel Interview</button>}
                            </Col>
                        </Row>
                    </div>
                </div>}
            </div>

        );
    }
}

export default CalendarConfirmed;