import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Header(props) {
    return (
        <Row bsClass='row no-pad mainNav'>
            <Col className='nav' xs={12}>
                <img className='navImg' alt="home depot logo" src={props.imgSrc} />
                <div className="navTitle">{props.title}</div>
            </Col>
        </Row>
    )
}

Header.defaultProps = {
    title: 'Title'
}

Header.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired 
};

export default Header;