import axios from 'axios';
import CacheBuster from '../Util/CacheBuster';
import redirectService from "./RedirectService";

var SchedulingService = {
    getAvailableTimeslots:function(reqnId, days){
        console.log("actually in the service");
        return axios.get('/api/scheduling/availabletimeslots/' +days+ '?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json",

            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getRequisitionAvailability:function(reqnId, isTacOffer){

        return axios.get('/api/scheduling/openingsavailable?tacOffer='+isTacOffer+'&cb='+CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json",
                }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    reserveTimeSlotAndUpateStatus:function(reqnId, applId, timeSlot){
        return axios.put('/api/scheduling/reserveTimeSlotAndUpdateStatus/' + timeSlot, '', {headers: { "Content-type":"application/json",
            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },reserveTimeSlotAndUpdateOfferStatus:function(reqnId, applId, timeSlot){
        return axios.put('/api/scheduling/reserveTimeSlotAndUpdateTACStatus/' + timeSlot, '', {headers: { "Content-type":"application/json",
                }}).then(function(response){
            return response;
        });
    },rescheduleTimeSlotAndUpateStatus:function(reqnId, applId, timeSlot){
        return axios.put('/api/scheduling/reschedule/'  + timeSlot, '', {headers: { "Content-type":"application/json",
                }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },rescheduleTimeSlotAndUpdateTacStatus:function(reqnId, applId, timeSlot){
        return axios.put('/api/scheduling/reschedule/' + timeSlot+'?tacToOffer=true', '', {headers: { "Content-type":"application/json",
                }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    getScheduledInterviewInfo:function(applId){
        return axios.get('/api/scheduling/interviewScheduledInfo?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json",
            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e))
    },
    getScheduledOfferInfo:function(applId){
        return axios.get('/api/scheduling/offerScheduledInfo?cb=' + CacheBuster.generateRandomString(), {headers: { "Content-type":"application/json",
                }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e))
    },
    cancelInterview:function(reqnId, applId, timeSlot){
        return axios.put('/api/scheduling/cancel/' + timeSlot, '', {headers: { "Content-type":"application/json",
                }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    cancelOffer:function(reqnId, applId, timeSlot){
        return axios.put('/api/scheduling/offer/cancel/'  + timeSlot, '', {headers: { "Content-type":"application/json",
                }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    updateDeclineReason:function(applId, reasonCode){
        return axios.put('/api/scheduling/decline/'+reasonCode, '',{headers: { "Content-type":"application/json",
                }}).then(function (response){
            return response;
        }).catch(e => redirectService.redirect(e))
    },
    updateAccommodation:function(reqnId, applId){
        return axios.put('/api/scheduling/accommodation', '',{headers: { "Content-type":"application/json",
                }}).then(function (response){
            return response;
        }).catch(e => redirectService.redirect(e))
    }
}

export default SchedulingService;