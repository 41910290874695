import React from 'react';
import SchedulingService from '../Services/SchedulingService';
import OfferConfirmedDetails from './OfferConfirmedDetails';
import ErrorBanner from './ErrorBanner';
import Time from '../Util/Time';
import InfoModal from "./InfoModal";
import RequisitionService from "../Services/RequisitionService";
import Loader from "./Loader";

class OfferConfirmed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dayText: '',
            time: '',
            employmentType: '',
            bgcRequired: false,
            drugTestRequired: false,
            position: '',
            addressLine1:'',
            addressLine2:'',
            scheduleDays:'',
            scheduleTimes:'',
            wage:'',
            timeSlot:'',
            requisition: '',
            rescheduled: false,
            availability: true,
            timeLeftToReschedule:true,
            accommodation: false,
            showInfoModal: false,
            eventLocation: '',
            eventAddress: '',
            eventCity: '',
            eventZipCode: '',
            eventState: '',
            isLoaded: false
        }
    }

    async componentDidMount() {
        await this.getScheduledOfferInfo();
    }

    getScheduledOfferInfo = async () => {
        const applicantID = window.sessionStorage.getItem('applicantID');
        const scheduleOfferResponse = await SchedulingService.getScheduledOfferInfo(applicantID).then(resp => {
            return resp
        });
        await this.updateOfferInfoState(scheduleOfferResponse);
    }

    updateOfferInfoState = async (scheduleOfferResponse) => {
        if (scheduleOfferResponse.data.hasOwnProperty("dayText")) {
            const calResponse = await RequisitionService.getCalendarDetails().then(resp => {
                return resp
            });
            this.setState(function () {
                return {
                    dayText: scheduleOfferResponse.data.dayText,
                    time: Time.getTimeFromTimeString(scheduleOfferResponse.data.time),
                    employmentType: scheduleOfferResponse.data.employmentType,
                    bgcRequired: scheduleOfferResponse.data.bgcRequired,
                    drugTestRequired: scheduleOfferResponse.data.drugTestRequired,
                    position: scheduleOfferResponse.data.position,
                    addressLine1: scheduleOfferResponse.data.addressLine1,
                    addressLine2: scheduleOfferResponse.data.addressLine2,
                    scheduleDays: scheduleOfferResponse.data.scheduleDays,
                    scheduleTimes: scheduleOfferResponse.data.scheduleTimes,
                    wage: scheduleOfferResponse.data.wage,
                    timeSlot: scheduleOfferResponse.data.timeSlot,
                    requisition: scheduleOfferResponse.data.requisition,
                    rescheduled: scheduleOfferResponse.data.rescheduled,
                    availability: scheduleOfferResponse.data.availability,
                    timeLeftToReschedule: scheduleOfferResponse.data.timeLeftToReschedule,
                    accommodation: scheduleOfferResponse.data.accommodation,
                    showInfoModal: calResponse.data.reqCalendarTypeCd === '20',
                    eventLocation: calResponse.data.eventLocation ? calResponse.data.eventLocation  : '',
                    eventAddress: calResponse.data.eventAddress? calResponse.data.eventAddress  : '',
                    eventCity: calResponse.data.eventCity? calResponse.data.eventCity  : '',
                    eventZipCode: calResponse.data.eventZipCode? calResponse.data.eventZipCode  : '',
                    eventState: calResponse.data.eventState? calResponse.data.eventState  : '',
                    isLoaded: true
                }
            });
        } else {
            this.setState(function () {
                return {
                    submitErrorMessage: 'Not able to retrieve your interview information.'
                }
            });
        }
    }

    handleCloseInfoModal = () => {
        this.setState({showInfoModal: false});
    }

    closeEventHandler = () => {
        this.setState(function() {
            return {
                submitErrorMessage:''
            }
        })
    }

    render () {
        let {isLoaded, showInfoModal, eventLocation, eventAddress, eventCity, eventZipCode, eventState} = this.state;
        let eventAddress1 = eventLocation === '' ? '' : eventLocation;
        let eventAddress2 = eventAddress === '' ? '' : eventAddress;
        let eventAddress3 = eventCity === '' && eventState === '' && eventZipCode === '' ? '' : eventCity + ' ' + eventState + ' ' + eventZipCode;

        return (
            <div>
                <ErrorBanner submitErrorMessage={this.state.submitErrorMessage}
                             closeEventHandler={this.closeEventHandler}/>
                {!isLoaded && <Loader/>}
                {isLoaded && <InfoModal show={showInfoModal} eventLocation={eventLocation} eventAddress={eventAddress}
                                        eventCity={eventCity} eventZipCode={eventZipCode}
                                        eventState={eventState} handleClose={this.handleCloseInfoModal}/>}
                {isLoaded && <OfferConfirmedDetails history={this.props.history} dayText={this.state.dayText}
                                                    time={this.state.time}
                                                    employmentType={this.state.employmentType}
                                                    position={this.state.position}
                                                    locationLine1={this.state.addressLine1}
                                                    locationLine2={this.state.addressLine2}
                                                    reqnNbr={this.state.requisition} timeSlot={this.state.timeSlot}
                                                    rescheduled={this.state.rescheduled}
                                                    scheduleDays={this.state.scheduleDays}
                                                    scheduleTimes={this.state.scheduleTimes}
                                                    bgcRequired={this.state.bgcRequired}
                                                    drugTestRequired={this.state.drugTestRequired}
                                                    wage={this.state.wage}
                                                    availability={this.state.availability}
                                                    timeLeftToReschedule={this.state.timeLeftToReschedule}
                                                    accommodation={this.state.accommodation}
                                                    eventAddress1={eventAddress1}
                                                    eventAddress2={eventAddress2}
                                                    eventAddress3={eventAddress3}/>}
            </div>
        );
    }
}

export default OfferConfirmed;