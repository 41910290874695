import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SchedulingService from '../Services/SchedulingService';
import PageEnum from '../Util/PageEnum';
import UpdateAndRoutingService from "../Services/UpdateAndRoutingService";
import PositionDetails from "./PositionDetails";
import RequisitionService from "../Services/RequisitionService";

class OfferConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dayText: '',
            time: '',
            locationLine1: '',
            locationLine2: '',
            timeSlot: '',
            reqnNbr: '',
            jobDescription: '',
            pageErrorMessage: '',
            reschedule: false,
            cancel: false,
            days: '',
            employmentType: '',
            pay: '',
            times: '',
            eventLocation: '',
            eventAddress: '',
            eventCity: '',
            eventZipCode: '',
            eventState: '',
            isLoaded: false
        }

    }

    async componentDidMount() {
        if(this.props.location.state) {
            const calResponse = await RequisitionService.getCalendarDetails(this.props.location.state.reqnNbr).then(resp => {
                return resp;
            });
            this.setState(function() {
                return {
                    dayText: this.props.location.state.hasOwnProperty("dayText") ? this.props.location.state.dayText : '',
                    time: this.props.location.state.hasOwnProperty("time") ? this.props.location.state.time : '',
                    locationLine1: this.props.location.state.hasOwnProperty("locationLine1") ? this.props.location.state.locationLine1 : '',
                    locationLine2: this.props.location.state.hasOwnProperty("locationLine2") ? this.props.location.state.locationLine2 : '',
                    timeSlot: this.props.location.state.hasOwnProperty("timeSlot") ? this.props.location.state.timeSlot : '',
                    reschedule:  this.props.location.state.reschedule,
                    cancel: this.props.location.state.cancel,
                    reqnNbr: this.props.location.state.hasOwnProperty("reqnNbr") ? this.props.location.state.reqnNbr : '',
                    jobDescription: this.props.location.state.hasOwnProperty("jobDescription") ? this.props.location.state.jobDescription : '',
                    employmentType: this.props.location.state.hasOwnProperty("employmentType") ? this.props.location.state.employmentType : '',
                    days: this.props.location.state.hasOwnProperty("days") ? this.props.location.state.days : '',
                    times: this.props.location.state.hasOwnProperty("times") ? this.props.location.state.times : '',
                    pay: this.props.location.state.hasOwnProperty("pay") ? this.props.location.state.pay : '',
                    eventLocation: calResponse.data.eventLocation ? calResponse.data.eventLocation : '',
                    eventAddress: calResponse.data.eventAddress ? calResponse.data.eventAddress : '',
                    eventCity: calResponse.data.eventCity ? calResponse.data.eventCity : '',
                    eventZipCode: calResponse.data.eventZipCode ? calResponse.data.eventZipCode : '',
                    eventState: calResponse.data.eventState ? calResponse.data.eventState : '',
                    isLoaded: true
                }
            });
        }
    }

    confirmClickHandler = (e) =>  {
        var applicantId = window.sessionStorage.getItem('applicantID');

        if (this.state.reschedule === true){

            SchedulingService.rescheduleTimeSlotAndUpdateTacStatus(this.state.reqnNbr, applicantId, this.state.timeSlot).then(this.rescheduleOnComplete).catch(() => {
                this.setError('Error processing data.  Please try again.');
            })};

    }

    setError (errorMessage) {
        this.setState(function () {
            return {pageErrorMessage: errorMessage}
        });
    }

    setLoaded (isLoaded){
        this.setState(function () {
            return {loaded : isLoaded}
        });
    }
    // cancelOnComplete = (resp) => {
    //     if(resp && resp.data) {
    //         if(resp.data.success){
    //             this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=19"});
    //         }else {
    //             this.setError('Error Canceling interview.  Please try again.  If this persists, please contact the store.');
    //         }
    //     } else {
    //        this.setError('Error receiving response from backend.  If this persists please try from the beginning or contact the store.');
    //     }
    // }

    rescheduleOnComplete = (resp) => {
        this.setLoaded(true);
        var applicantId = window.sessionStorage.getItem('applicantID');
        if(resp && resp.data) {
            if(resp.data.message) {
                this.props.history.push({pathname: PageEnum.Calendar.url, search: "?reqnId=" + this.state.reqnNbr+"&reschedule=true&offer=true", state: { errorMessage: resp.data.message }});
            } else if(resp.data.success) {
                UpdateAndRoutingService.updateAndGetRouteAuthentication().then(this.updateAndGetRouteOnComplete);
            }
        } else {
            this.setState(function() {
                return { pageErrorMessage : 'Error processing data.  Please try again.'}
            });
        }
    }


    updateAndGetRouteOnComplete = (response) => {
        this.setLoaded(true);
        if(response.data.msgId){
            this.props.history.push({pathname: PageEnum[(response.data.pages)].url, search: "?msgId=" + response.data.msgId});
         } else {
             this.props.history.push({pathname: PageEnum[(response.data.pages)].url});
         }
    }

    cancelClickHandler = (e) => {
        this.props.history.goBack();
    }

    render () {
        let eventAddress1 = this.state.eventLocation  === '' ? '' : this.state.eventLocation;
        let eventAddress2 = this.state.eventAddress === '' ? '' : this.state.eventAddress;
        let eventAddress3 = this.state.eventCity  === '' && this.state.eventState === '' && this.state.eventZipCode === '' ? '' : this.state.eventCity + ' ' + this.state.eventState + ' ' + this.state.eventZipCode;

        return (
            <div>
                {this.state.pageErrorMessage ? <div className="pageErrorMessage">{this.state.pageErrorMessage} </div> : ''}
              {this.state.isLoaded ? (   <div>
                {(this.state.reschedule &&
                    <Row>
                        <Col xs={12}>
                            <h2>Confirm Reschedule</h2>
                            <p>Please note: You are only able to reschedule one time for this position. If you are
                                unable to attend the rescheduled time, you will no longer be considered for
                                this position.</p>
                        </Col>
                    </Row>)
                ||
                (this.state.cancel && <Row>
                        <Col xs={12} id="cancelText">
                            <h2>Cancel:</h2>
                            <p>You have selected to cancel. Canceling means you no longer
                            want to be considered for this position at this time.</p>
                        </Col>
                    </Row>
                ) }
                {<PositionDetails dayText={this.state.dayText} time={this.state.time}
                                  employmentType={this.state.employmentType} jobDescription={this.state.jobDescription}
                                  pay={this.state.pay} days={this.state.days} times={this.state.times}
                                  addressLine1={this.state.locationLine1 } addressLine2={this.state.locationLine2}
                                  eventAddress1={eventAddress1}
                                  eventAddress2={eventAddress2} eventAddress3={eventAddress3}/>}
                                  {this.state.cancel && <div><p><b>Are you sure you want to cancel your offer?</b></p></div>}
                                  {this.state.reschedule && <div className="footer"><b>Are you sure you want to reschedule?</b> <br/>&nbsp; </div>}
                <div>
                    <Row>
                        <Col xs={12} sm={this.state.reschedule||this.state.cancel?5:3} className="largeVerticalMargins">
                            <button id="butConfirmation" className="button primary submitbutton" type="button" onClick={this.confirmClickHandler}>

                                {this.state.reschedule ? <span>Confirm Reschedule</span> : this.state.cancel ? <span>Cancel</span>:<span>Confirm</span>}
                            </button>
                        </Col>

                        <Col xs={12} sm={3} className='pull-right'>
                            <button id="backButton" className="button submitbutton" type="button" onClick={this.cancelClickHandler}>Cancel</button>
                        </Col>
                    </Row>
                </div>
              </div>) : <div className="loader">Loading...</div>}
            </div>
        );
    }
}

export default OfferConfirmation;