import AlternateRequisition from "../components/AlternateRequisition";

var PageEnum = {
Questions: { name: "Questions", url: "/questions", search:""},
    StillInterested : {name: "StillInterested", url:"/stillinterested", search:""},
    Message: { name: "Message", url: "/message", search:""},
    Calendar: { name: "Calendar", url: "/calendar", search:""},
    OfferCalendar: { name: "OfferCalendar", url: "/calendar", search:"?offer=true"},
    Authentication: { name: "Authenticate", url: "/authenticate", search:""},
    CalendarConfirmation: { name: "CalendarConfirmation", url: "/calendar/confirmation", search:""},
    CalendarConfirmed: { name: "CalendarConfirmed", url: "/calendar/confirmed", search:""},
    InterviewConfirmed: { name: "InterviewConfirmed", url: "/interview/confirmed", search:""},
    MinimumAge: {name: "MinimumAge", url: "/minimumAge", search:""},
    Offer: {name: "Offer", url:"/offer/info", search:""},
    NextSteps: {name:"NextSteps", url:"/nextSteps", search:""},
    Leader: {name:"Leader", url:"/leader", search:""},
    TellUsWhy: {name:"TellUsWhy", url:"/tellUsWhy", search:""},
    OfferConfirmed: {name:"OfferConfirmed", url:"/offer/confirmed", search:""},
    OfferConfirmation: {name:"OfferConfirmation", url:"/offer/confirmation", search:""},
    Schedule: {name:"Schedule", url:"/schedule/shifts", search:""},
    ScheduleAccommodation: {name:"ScheduleAccommodation", url:"/schedule/accommodation", search:""},
    AlternateRequisition: {name:"AlternateRequisition", url:"/alternateRequisition", search:""}
}

export default PageEnum;