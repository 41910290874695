import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SchedulingService from '../Services/SchedulingService';
import PageEnum from '../Util/PageEnum';
import InterviewDetails from '../components/InterviewDetails';
import UpdateAndRoutingService from "../Services/UpdateAndRoutingService";

class CalendarConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dayText: '',
            time: '',
            locationLine1: '',
            locationLine2: '',
            timeSlot: '',
            reqnNbr: '',
            position: '',
            phoneNumber: '',
            pageErrorMessage: '',
            reschedule: false,
            cancel: false,
            loaded: true
        }

    }

    UNSAFE_componentWillMount() {
        if(this.props.location.state){
            this.setState(function(){
                return {
                    dayText: this.props.location.state.hasOwnProperty("dayText") ? this.props.location.state.dayText : '',
                    time: this.props.location.state.hasOwnProperty("time") ? this.props.location.state.time : '',
                    locationLine1: this.props.location.state.hasOwnProperty("locationLine1") ? this.props.location.state.locationLine1 : '',
                    locationLine2: this.props.location.state.hasOwnProperty("locationLine2") ? this.props.location.state.locationLine2 : '',
                    timeSlot: this.props.location.state.hasOwnProperty("timeSlot") ? this.props.location.state.timeSlot : '',
                    reschedule:  this.props.location.state.reschedule,
                    cancel: this.props.location.state.cancel,
                    reqnNbr: this.props.location.state.hasOwnProperty("reqnNbr") ? this.props.location.state.reqnNbr : '',
                    position: this.props.location.state.hasOwnProperty("position") ? this.props.location.state.position : '',
                    phoneNumber: this.props.location.state.hasOwnProperty("phoneNumber") ? this.props.location.state.phoneNumber : '',
                    loaded:true

                }
            });
        }
    }

    confirmClickHandler = () =>  {
        let applicantId = window.sessionStorage.getItem('applicantID');
        this.setLoaded(false);


            if(this.state.cancel === true)
            {
                SchedulingService.cancelInterview(this.state.reqnNbr, applicantId, this.state.timeSlot).then(this.cancelOnComplete).catch(() => {
                    this.setError('Error processing data.  Please try again.');
                    this.setLoaded(true);
                });
            }else if (this.state.reschedule === true){
                SchedulingService.rescheduleTimeSlotAndUpateStatus(this.state.reqnNbr, applicantId, this.state.timeSlot).then(this.rescheduleOnComplete).catch(() => {
                    this.setError('Error processing data.  Please try again.');
                    this.setLoaded(true);
                });
            }else {
                SchedulingService.reserveTimeSlotAndUpateStatus(this.state.reqnNbr, applicantId, this.state.timeSlot).then(this.reserveTimeSlotAndUpateStatusOnComplete).catch(() => {
                    this.setError('Error processing data.  Please try again.');
                    this.setLoaded(true);
                });
            }

    }

    setError (errorMessage) {
        this.setState(function () {
            return {pageErrorMessage: errorMessage}
        });
    }

    setLoaded (isLoaded){
        console.log(`set loaded ${isLoaded}`);
        this.setState(function () {
            return {loaded : isLoaded}
        });
    }

    cancelOnComplete = (resp) => {
        if(resp && resp.data) {
            if(resp.data.success){
                this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=19"});
            }else {
                this.setError('Error Canceling interview.  Please try again.  If this persists, please contact the store.');
                this.setLoaded(false);
            }
        } else {
           this.setError('Error receiving response from backend.  If this persists please try from the beginning or contact the store.');
            this.setLoaded(false);
        }
    }

    rescheduleOnComplete = (resp) => {
        let applicantId = window.sessionStorage.getItem('applicantID');
        if(resp && resp.data) {
            if(resp.data.message) {
                this.props.history.push({pathname: PageEnum.Calendar.url, search: "?reqnId=" + this.state.reqnNbr+"&reschedule=true", state: { errorMessage: resp.data.message }});
            } else if(resp.data.success) {
                UpdateAndRoutingService.updateAndGetRouteAuthentication().then(this.updateAndGetRouteOnComplete);
                //this.props.history.push({pathname: PageEnum.CalendarConfirmed.url, state: {locationLine1: this.state.locationLine1, locationLine2: this.state.locationLine2, dayText: this.state.dayText, time: this.state.time} });
            }
        } else {
            this.setState(function() {
                this.setLoaded(false);
                return { pageErrorMessage : 'Error processing data.  Please try again.'}
            });
        }
    }

    reserveTimeSlotAndUpateStatusOnComplete = (resp) => {
        let applicantId = window.sessionStorage.getItem('applicantID');
        if(resp && resp.data) {
            if(resp.data.message) {
                this.props.history.push({pathname: PageEnum.Calendar.url, search: "?reqnId=" + this.state.reqnNbr, state: { errorMessage: resp.data.message }});
            } else if(resp.data.success) {
                UpdateAndRoutingService.updateAndGetRouteAuthentication().then(this.updateAndGetRouteOnComplete);
                //this.props.history.push({pathname: PageEnum.CalendarConfirmed.url, state: {locationLine1: this.state.locationLine1, locationLine2: this.state.locationLine2, dayText: this.state.dayText, time: this.state.time} });
            }
        } else {
            this.setState(function() {
                return {
                    pageErrorMessage : 'Error processing data.  Please try again.',
                    loaded:true
                }

            });
        }
    }

    updateAndGetRouteOnComplete = (response) => {
        if(response.data.msgId){
            this.props.history.push({pathname: PageEnum[(response.data.pages)].url, search: "?msgId=" + response.data.msgId});
         } else {
             this.props.history.push({pathname: PageEnum[(response.data.pages)].url});
         }
    }

    backClickHandler = () => {
        this.props.history.goBack();
    }

    cancelClickHandler = () => {
        let applicantId = window.sessionStorage.getItem('applicantID');
        UpdateAndRoutingService.updateAndGetRouteAuthentication().then(this.updateAndGetRouteOnComplete);
    }

    render () {
        return (
            <div>
            {this.state.loaded ? (
            <div>
                {this.state.pageErrorMessage ? <div className="pageErrorMessage">{this.state.pageErrorMessage} </div> : ''}
                {(this.state.reschedule &&
                    <Row>
                        <Col sm={12}>
                            <h2>Confirm Interview:</h2>
                            <p>Please note: You are only able to reschedule one time for this position. If you are
                                unable to attend the rescheduled interview time, you will no longer be considered for
                                this position.</p>
                        </Col>
                    </Row>)
                ||
                (this.state.cancel && <Row>
                        <Col sm={12} id="cancelText">
                            <h2>Cancel Interview:</h2>
                            <p>You have selected to cancel this interview. Canceling this interview means you no longer
                            want to be considered for this position at this time.</p>
                        </Col>
                    </Row>
                ) ||
                <Row>
                    <Col sm={12}>
                        <h2>You have selected an interview for:</h2>
                    </Col>
                </Row>}
                {<InterviewDetails dayText={this.state.dayText} time={this.state.time} position={this.state.position} locationLine1={this.state.locationLine1} locationLine2={this.state.locationLine2} phoneNumber ={this.state.phoneNumber} showNumber={true} />}
                {this.state.cancel && <div><p><b>Are you sure you want to cancel your interview?</b></p></div>}
                <div>
                    <Row>
                        <Col sm={12} md={this.state.reschedule||this.state.cancel?5:3} className="largeVerticalMargins">
                            <button id="butConfirmation" className={(this.state.reschedule)? "button primary submitbutton":"button primary submitbutton"} type="button" onClick={this.confirmClickHandler}>

                                {this.state.reschedule ? <span>Confirm reschedule</span> : this.state.cancel ? <span>Cancel Interview</span>:<span>Confirm</span>}
                            </button>
                        </Col>
                        {this.state.reschedule && <Col sm={12} md={4} className='cancelBtn float-left bottomButtonBuffer'>
                            <button id="cancelButton" className="button submitbutton" type="button" onClick={this.cancelClickHandler}>
                               Cancel
                            </button>

                        </Col>}
                        <Col sm={12} md={3} className={(this.state.reschedule) ? "": (this.state.cancel)?"offset-md-4":" offset-md-1 "} >
                            <button id="backButton" className="button submitbutton" type="button" onClick={this.backClickHandler}>Back</button>
                        </Col>
                    </Row>
                </div>

            </div>): <div className="loader">Loading...</div> }
            </div>
        );
    }
}

export default CalendarConfirmation;