import React from 'react';
import { Row, Col } from 'react-bootstrap';
import QuestionService from '../Services/QuestionService';
import titleCase from 'title-case';
import UpdateAndRoutingService from '../Services/UpdateAndRoutingService';
import ErrorBanner from '../components/ErrorBanner';
import PageEnum from '../Util/PageEnum';
import PhoneScreenService from '../Services/PhoneScreenService';
import RequisitionService from '../Services/RequisitionService';
import Loader from '../components/Loader';


class ScheduleAccommodation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            applicantId: window.sessionStorage.getItem('applicantID'),
            accommodationQuestion: 23,
            loaded:false,
            question:{text:''}

        }

        this.noEventHandler = this.noEventHandler.bind(this);
    }
    
    disableLoadingIcon = (response) => {
        this.setState(function () {
            return {
                loaded: response
            }
        });
    } 

    componentDidMount() {
        var applId = window.sessionStorage.getItem('applicantID');
        this.disableLoadingIcon(false);
        this.setState(function () {
            return {
                applId: applId
            }
        });

        QuestionService.getQuestionById(17).then(this.getQuestionOnComplete);
    }

    getQuestionOnComplete = (response) => {
        if (response && response.data) {
            let question = response.data;
            console.log(question);
            this.disableLoadingIcon(true);
            this.setState(function () {
                return {
                    question: question,
                }
            });
        }
    }

    requisitionDetailsOnComplete = (response) => {
        if (response && response.data) {
            let scheduleMatch = response.data.scheduleIndicator;
            this.setState(function() {
                return {
                    scheduleMatchIndicator:scheduleMatch
                }
            });
        }
    }

    noEventHandler = () => {
            PhoneScreenService.updateMinReqAndPhoneScreenStatus(this.state.applicantId, 5, 5).then(this.updateMinReqAndPhoneScreenStatusOnComplete);
            RequisitionService.getRequisitionNumberByApplId(this.state.applicantId).then(this.getRequisitionNumberByApplIdOnComplete);
            QuestionService.updatePhoneScreenQuestionFlag(this.state.applicantId, this.state.question.phoneScreenQuestionCode, "N").then(this.updatePhoneScreenQuestionFlag_NoAccommodationOnComplete).catch(() => {
                this.disableLoadingIcon(true);
                this.setState(function () {
                    return {
                        errorMessage: 'Error processing data.  Please try again.' }
                });
            });
    }

    closeEventHandler = (e) => {
        this.setState(function() {
            return {errorMessage: ''}
        });
    }

    getRequisitionNumberByApplIdOnComplete = (resp) => {
        if (!resp || !resp.data) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
        else{
            var reqNbr = resp.data.requisitionNumber;
            PhoneScreenService.updateInterviewStatus(this.state.applicantId,reqNbr,0).then(this.updateInterviewStatusOnComplete);
            this.setState(function () {
                return {
                    reqnId: reqNbr
                }
            });
        }
    }

    isSchedulingQuestion = () => {
        if(this.state.schedulingQuestions.indexOf(this.state.questionsList[this.state.qIndex].phoneScreenQuestionCode) != -1){
            return true;
        } else {
            return false;
        }
    }



    updatePhoneScreenQuestionFlag_AccomodationOnComplete = (resp) => {       
        if (resp && resp.data && resp.data.rowsUpdated !== 1) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return {                    
                    errorMessage: 'Error processing data.  Please try again.' 
                }
            });
        }
        this.disableLoadingIcon(true);  
    }


    updatePhoneScreenQuestionFlag_NoAccommodationOnComplete = (resp) => {
        this.disableLoadingIcon(true);
        if (resp && resp.data && resp.data.rowsUpdated === 1) {
            //Changes for Job Recommendation Was route 13 now route 26 (Same page different msg)
            // this.props.history.push({ pathname: PageEnum.Message.url, search: "?msgId=13" });
            this.props.history.push({ pathname: PageEnum.Message.url, search: "?msgId=26" });
        } else {
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    updateMinReqAndPhoneScreenStatusOnComplete = (resp) => {
        if (!resp || !resp.data) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { 
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    updateInterviewStatusOnComplete = (resp) => {
        if (!resp || !resp.data || resp.data.rowsUpdated !== 1) {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }


    updatePhoneScreenQuestionFlagOnComplete = (resp) => {
        if (resp && resp.data && resp.data.rowsUpdated === 1) {
                UpdateAndRoutingService.updateAndRouteQuestions(this.state.applId).then(this.updateAndRouteQuestionsOnComplete);
        } else {
            this.disableLoadingIcon(true);
            this.setState(function () {
                return {
                    errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }
    
    yesEventHandler = () => {
         this.setState({
             loaded: !this.state.loaded  });
         QuestionService.updatePhoneScreenQuestionFlag(this.state.applicantId, this.state.question.phoneScreenQuestionCode, "Y").then(this.updatePhoneScreenQuestionFlagOnComplete).catch(() => {
             this.disableLoadingIcon(true);
             this.setState(function () {
                 return {
                     errorMessage: 'Error processing data.  Please try again.' }
             });
         });
    }


    updateAndRouteQuestionsOnComplete = (response) => {
        if (response.data.msgId) {
            this.props.history.push({ pathname: PageEnum[titleCase(response.data.pages)].url, search: "?msgId=" + response.data.msgId });
        } else {
            this.props.history.push({ pathname: PageEnum[titleCase(response.data.pages)].url });
        }
    }






    render() {


        return (
            <div>{this.state.loaded ?
                <div className="questions">
                    <ErrorBanner submitErrorMessage={this.state.errorMessage} closeEventHandler={this.closeEventHandler} />
                    <Row>
                        <Col xs={12} className='getQuestions'>
                            <span className="msg_txt" dangerouslySetInnerHTML={{ __html: this.state.question.text }} />
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={4}>
                            <button className="button yesno" type="button" onClick={this.yesEventHandler}> Yes </button>
                            <button className="button yesno" type="button" onClick={this.noEventHandler}> No </button>
                        </Col>
                    </Row>
                </div> : <Loader/>}
            </div>
        );
    }
}

export default ScheduleAccommodation;
