import axios from 'axios';
import redirectService from "./RedirectService";
import CacheBuster from "../Util/CacheBuster";



var StoreDetailsService = {
    getStoreDeatilsServiceByStoreDetails:function(storeNumber){
        return axios.get('/api/storeDetails/byStoreNumber?storeNumber=' + storeNumber,{headers: { "Content-Type":"application/json",
            }}).then(function(response){
            return response;
        });
    },
     getStoreDeatilsServiceByApplicantId:function(applicantId){
        return axios.get('/api/storeDetails/byApplicant?cb=' + CacheBuster.generateRandomString(),{headers: {"Content-Type":"application/json",
            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    }

}


export default StoreDetailsService;