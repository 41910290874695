import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageEnum from "../Util/PageEnum";
import PositionDetails from "../components/PositionDetails"
import RequisitionService from "../Services/RequisitionService";
import CandidateStatusService from "../Services/CandidateStatusService";
import DeclineModal from "./DeclineModal";
import Iframe from 'react-iframe'

class Offer extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            jobDescription: '',
            employmentType: '',
            pay: '',
            days: '',
            times: '',
            bgcRequired: false,
            drugTestRequired: false,
            addressLine1: '',
            addressLine2: '',
            requisitionId: '',
            url: '',
            showDecline: false,
            isU18State: false,
            isU18JobTitleCode: false

        }
    }

    componentDidMount() {
        this.getPositionDetails();
    }

    getPositionDetails = () => {
        let applicantID = window.sessionStorage.getItem('applicantID');
        RequisitionService.getPositionDetails(applicantID).then(this.getPositionDetailsOnComplete);
    }

     getPositionDetailsOnComplete = (resp) => {
         console.log('resp:' + resp.data);
         this.setState(function(){
             return {
                jobDescription: resp.data.jobDescription,
                employmentType:  resp.data.employmentType,
                pay: resp.data.pay,
                days: resp.data.days,
                times: resp.data.times,
                bgcRequired: resp.data.bgcRequired,
                drugTestRequired: resp.data.drugTestRequired,
                addressLine1: resp.data.addressLine1,
                addressLine2: resp.data.addressLine2,
                requisitionId: resp.data.requisitionId,
                isU18State: resp.data.u18State,
                isU18JobTitleCode: resp.data.u18JobTitleCode,
                url: resp.data.url,
                loaded:true
            }
        });

     }

    updateTacOfferStatusOnComplete = (resp) => {
        if (resp.data.rowsUpdated === 2){
            this.props.history.push({pathname: PageEnum.TellUsWhy.url, state:{exclude:'?exclude=7&exclude=8'}});

        }else{
        }
        this.setState({loaded:true});

    }

    yesDeclineClickHandler = () => {
        let applicantID = window.sessionStorage.getItem('applicantID');
        let reqId = this.state.requisitionId;
        CandidateStatusService.updateTacOfferStatus(reqId, 3).then(this.updateTacOfferStatusOnComplete);
        this.setState({loaded:false});
    }

    yesClickHandler = () => {
        this.props.history.push({pathname: PageEnum.NextSteps.url, state:{drugTestRequired: this.state.drugTestRequired, bgcRequired: this.state.bgcRequired, requisitionId:this.state.requisitionId, isU18State: this.state.isU18State, isU18JobTitleCode: this.state.isU18JobTitleCode}});
    }

    openDeclineModal = () => {
        this.setState({
            showDecline: true
        });
    }

    closeDeclineModal = () => {
        this.setState({
            showDecline: false
        });
    }


    render () {
        // let {bgcRequired, drugTestRequired} = this.state;

        return (
            <div>
                {this.state.loaded ? (
                    <div>
                        <Row>
                            <Col className='col-12'>

                                <div>
                                    <h2 class={"infoHeader"}>We'd like to offer you a job!</h2>
                                </div>
                                <div>
                                    <p>Here are your contingent offer details:</p>
                                </div>

                            </Col>
                        </Row>
                        {<PositionDetails employmentType={this.state.employmentType} jobDescription={this.state.jobDescription} pay={this.state.pay} days={this.state.days} times={this.state.times} addressLine1={this.state.addressLine1 } addressLine2={this.state.addressLine2}/>}
                        {this.state.url && (<div><div>Want to learn more about the position? Watch the video below to hear from some of our associates about their 'day in the life' for this role.</div>

                        <Row>
                            <br/>
                            <Col className='col-12'>
                                <div className="aspect-ratio" >
                                    {/*dangerouslySetInnerHTML={{ __html: '<iframe src={this.state.url} style="border: 0;" webkitallowfullscreen allowfullscreen frameborder="no" width="100%" height="405"></iframe>'}}*/}
                                    <Iframe url={this.state.url} width="100%" height="100%"/>
                                </div>

                            </Col>


                        </Row></div>)}
                        <br/>
                        <Row>
                            <Col className='col-12'>
                                *This offer is conditional based upon the passing of a background check.
                            </Col>
                        </Row>
                        <br/>


                        <Row className='topMargin'>
                            <Col xs sm ={12} md={8} className="largeVerticalMargins">
                                <button  className="button submitbutton bsize Big primary" type="button" onClick={this.yesClickHandler}>
                                    Yes, I'm Interested
                                </button>
                            </Col>
                            <Col xs sm ={12} md={3} >
                                <button  className="button submitbutton bsizeSmall topMargin" type="button" onClick={this.openDeclineModal}>
                                    No Thanks
                                </button>
                            </Col>
                        </Row>
                        <DeclineModal handleAccept={this.yesDeclineClickHandler} handleClose={this.closeDeclineModal} show={this.state.showDecline}/>
                    </div>



                ) : <div id="loader" className="loader">Loading...</div> }
            </div>



        );
    }

}

export default Offer;