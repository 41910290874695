import React from "react";
import {Col, Row} from "react-bootstrap";
import {CloseOutlined} from "@ant-design/icons";

const ConfirmModal = ({handleClose, show, handleAccept, children}) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return(
        <div className={showHideClassName}>
            <div className="modal-container">
                <CloseOutlined style={{
                    fontSize: "14px",
                    color: "red",
                    strokeWidth: "140", // --> higher value === more thickness the filled area
                    stroke: "red"}} className="x-close" aria-hidden="true" onClick={handleClose}/>
                {/*<div className="glyphicon glyphicon-remove x-close" aria-hidden="true" onClick={handleClose}></div>*/}
                <h2 className="popupHeader">Are you sure?</h2>
                {children}
                <Row className="modal-buttons">
                    <Col sm={12} md={6} className = "largeVerticalMargins">
                        <button className="button submitbutton bsize  primary" onClick={handleAccept}>Yes </button>
                    </Col>
                    <Col sm={12} md={3} >
                        <button className=" button submitbutton bsizeSmall bottom-right" onClick={handleClose}>No</button>
                    </Col>
                </Row>

            </div>
        </div>
    );
};

export default ConfirmModal;