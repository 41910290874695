var Time =  (function(){

    return {
        getTimeFromDate: function(dayTime) {
            var utcDate = new Date(dayTime.slice(0, 10) +  "T" + dayTime.slice(11, 18) + "0Z");
            if(!isNaN(utcDate)){
                var hour = utcDate.getUTCHours();
                var minutes =  ('0' + utcDate.getMinutes()).slice(-2);
                var noon = "am";
                if(hour > 11) {
                    if(hour !== 12){
                        hour = hour - 12;
                    }
                    noon = "pm";
                }
                return hour + ":" + minutes + " " + noon;
            } else {
                return "";
            }
        },
        getTimeFromTimeString(time){
            if(time.length === 5 && time.indexOf(":") !== -1){
                var hour = time.slice(0,2);
                var minutes = time.slice(3,5);
                var noon = "am";
                if(hour > 11) {
                    if(hour !== "12"){
                        hour = "" + (hour - 12);
                    }
                    noon = "pm";
                }
                if(hour.slice(0,1) === "0"){
                    hour = hour.slice(1);
                }
                return hour + ":" + minutes + " " + noon;
            } else
            {
                return "";
            }
        },
        tConvert (time) {
            // Check correct time format and split into components
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice (1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
                time[3] = ''; //Bye Milliseconds!
            }
            return time.join ('').padStart(8, '0'); // return adjusted time or original string
        }
    }
})();

export default Time; 