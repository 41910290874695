import axios from 'axios';
import redirectService from "./RedirectService";


let JobDetailsService = {
    getJobTitleDescription: function(reqnNbr){
        return axios.get('/api/phoneScreen/jobDetails?requisitionID=' + reqnNbr, {headers: { "Content-type":"application/json"}}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    }
}
export default JobDetailsService;