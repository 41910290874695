import React from 'react';
import { Row, Col } from 'react-bootstrap';
import MessageService from '../Services/MessageService';
import queryString from 'query-string';
import PageEnum from "../Util/PageEnum";
import DeAuthenticationService from "../Services/DeAuthenticationService";

class Message extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            msg_Id:'',
            msg_text: ''
        }
    }

    UNSAFE_componentWillMount() {
        this.getMessage();
    }

    deAuthentication = () => {
        window.sessionStorage.removeItem('CSSAuthenticated');
        window.sessionStorage.removeItem('applicantID');
        DeAuthenticationService.logout().then();
    }



    getMessage = () => {
        var applicantID = window.sessionStorage.getItem('applicantID');
        var qs = queryString.parse(this.props.location.search);
        var msgId = qs.msgId;

        MessageService.getMessage(msgId, applicantID).then(this.getMessageOnComplete);
    }
    
    getMessageOnComplete = (resp) => {
        this.deAuthentication();
        this.setState(function(){
            return {
                msg_Id: resp.data.msg_id,
                msg_txt:  resp.data.msg_txt
            }
        });
    }

    logoutHandler = () => {
        this.props.history.push({ pathname: PageEnum.Authentication.url });
    }

    renderButtonText(id){
        switch(id) {
            case 19:
            case 23:
            case 26:
            case 27:
            case 28:
            case 29:
                return 'Log-out';
            case 22:
                return 'Confirm and Log-out';
            case 20:
            case 24:
            case 25:
                return 'Thanks';
            default:
                return false;
        }
    }

    render () {
        let logoutText = this.renderButtonText(this.state.msg_Id);

        return (
            <div className="messageFormat">
                <Row>
                    <Col className="col-12">
                        <span className="msg_txt" dangerouslySetInnerHTML={{__html: this.state.msg_txt}} />
                    </Col>
                </Row>


                {(logoutText) && <Row className="bottomButton">
                        <Col sm={12} md={4} className="largeVerticalMargins">
                            <button id="logoutBtn" className="button primary submitbutton bsize" type="button" onClick={this.logoutHandler}>{logoutText}</button>
                        </Col>

                    </Row>}

            </div>

        );
    }

}

export default Message;