import axios from 'axios';
import redirectService from "./RedirectService";


var DeAuthenticationService = {
    logout: function(){
        return axios.get('/logout', {headers: { "Content-type":"application/json"
            }}).then(function(response){
            return response;
        }).catch(e => redirectService.redirect(e));
    }
}

export default DeAuthenticationService;